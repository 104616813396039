<template>
<div class="survey-main" ref="surveyMain">
    <h1 class="survey-title">{{ form.name }}</h1>
    <div class="survey-page"
        v-for="(page, index) in pages" 
            :key="index"
            v-show="activePage === index">
        <div class="survey-element" 
            v-for="element in page" 
            :key="element.surveyElementId"
            :class="elementClass(element)">
            <template v-if="element.type === 'conditionalArea'">
                <conditional-area v-bind="{ element }"
                    v-show="showConditional(element)">
                    <template v-slot="{ item }">
                        <component 
                            v-bind="{ 
                                element: item,
                                initialValue: elementInitialValue(item)
                            }"    
                            :is="getElementComponent(item)"
                            @valueChange="onValueChange">
                        </component>
                        <ElementErrors 
                            v-if="showError(item)" 
                            :errorMessage="errorMessage(item)" />
                    </template>
                </conditional-area>
            </template>
            <template v-else>
                <component 
                    v-bind="{ 
                        element,
                        initialValue: elementInitialValue(element)
                    }"    
                    :is="getElementComponent(element)"
                    @valueChange="onValueChange">
                </component>
                <ElementErrors 
                    v-if="showError(element)" 
                    :errorMessage="errorMessage(element)" />
            </template>
        </div>
    </div>
    <div class="survey-navigation">
        <button v-if="activePage !== 0"
            @click="changePage(-1)" 
            style="margin-right: auto;">
            {{ $_locale('previous') }}
        </button>

        <button v-if="activePage === pages.length - 1"
            @click="save">
            {{ sendButtonText }}
        </button>

        <button v-else
            @click="changePage(1)">
            {{ $_locale('next') }}
        </button>
    </div>
</div>
</template>

<script>
import { 
    getTimestamp,
    doAnswerInjection,
    // preHandleElements,
    handleRepeaterAnswers,
    removeHiddenAnswers,
    handleMatchAnswers,
    validate,
    validateConditions,
} from './helpers/surveyService.js'
import ContentElement from './components/ContentElement.vue'
import PlaceholderElement from './components/PlaceholderElement.vue'
import ElementErrors from './components/ElementErrors.vue'
import TextField from './components/TextField.vue'
import TextArea from './components/TextArea.vue'
import LinearScale from './components/LinearScale.vue'
import CheckRadio from './components/CheckRadio.vue'
import ProductsSelect from './components/ProductsSelect.vue'
import DropDown from './components/DropDown.vue'
import ContactDetails from './components/ContactDetails.vue'
import UserConsent from './components/UserConsent.vue'
import ConditionalArea from './components/ConditionalArea.vue'

const QUESTION_COMPONENTS = {
    textfield: TextField,
    textarea: TextArea,
    linearscale: LinearScale,
    checkbox: CheckRadio,
    products: ProductsSelect,
    select: DropDown,
}
const ELEMENT_COMPONENTS = {
    contactDetails: ContactDetails,
    companyDetails: null,
    billingDetails: null,
    consent: UserConsent,
    invitation: null,
    trainerRepeater: null,
    attendeeDetails: null,
}

export default {
    emits: ['done','priceSummaryChange'],
    props: {
        form: {
            type: Object,
            required: true,
        },
        customElements: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        injectAnswers: {
            type: Object,
            required: false,
        },
    },
    components: {
        ElementErrors,
        PlaceholderElement,
        ConditionalArea,
    },
    data: () => ({
        errors: {},
        answers: {},
        elements: [],
        activePage: 0,
        startTime: null,
    }),
    computed: {
        elementInitialValue() {
            const answers = this.injectAnswers

            return (element) => {
                if (!answers) return null;
                
                const id = element.questionId ? `q-${ element.questionId }` : element.type
                const answer = answers[id]
                
                if (!answer) return null;

                return element.questionId ? answer.value : answer;
            } 
        },
        showError() {
            const errors = this.errors
            
            return (element) => {
                const id = element.questionId || element.type;
                const error = errors[id]
                
                if (!error) return false;

                return error.show
            }
        },
        errorMessage() {
            const errors = this.errors

            return (element) => {
                const id = element.questionId || element.type;
                const error = errors[id]

                if (!error) return '';

                return error.message
            }
        },
        pages() {
            const elements = this.elements
            const findElements = (type) => (element) => element.type === type;
            const consentElement = {
                surveyElementId: 'consent', 
                type: 'consent'
            }
            const pages = [[]];
            const [ conditionalWithContactDetails ] = elements
            .filter(findElements('conditionalArea'))
            .filter((element) => {
                return !!element.elements.filter(findElements('contactDetails'))[0]
            })
            
            if (conditionalWithContactDetails) {
                conditionalWithContactDetails.elements.push(consentElement);
            }        
            
            elements.forEach((element, key) => {
                if (element.type === 'pagechange'
                        && (key !== 0 || key !== elements.length - 1)) {
                    pages.push([]);
                } else {
                    pages[pages.length - 1].push(element);
                }
            })
            
            const [ pageWithContactDetails ] = pages.filter((page) => {
                return !!page.filter(findElements('contactDetails'))[0]
            })
            
            if (pageWithContactDetails) {
                pageWithContactDetails.push(consentElement);
            }        
        
            return pages;
        },
        notSpecialElement() {
            const specialElements = ['question','contactDetails','companyDetails','billingDetails','consent','conditionalArea','invitation','trainerRepeater','attendeeDetails'];
            
            return (element) => specialElements.includes(element.type) === false;
        },
        getElementComponent() {
            const specialElements = ['question','contactDetails','companyDetails','billingDetails','consent','conditionalArea','invitation','trainerRepeater','attendeeDetails'];

            return (element) => {
                if (specialElements.includes(element.type) === false) {
                    return ContentElement
                } else if (element.type === 'question') {
                    const { questionType } = element
                    const overrideElement = this.customElements[questionType]
                    const defaultElement = QUESTION_COMPONENTS[questionType]
                    // try first from the override elements
                    if (overrideElement) {
                        return overrideElement
                    } else if (defaultElement) {
                        return defaultElement
                    }
                } else {
                    const { type } = element
                    const overrideElement = this.customElements[type]
                    const defaultElement = ELEMENT_COMPONENTS[type]

                    // try first from the override elements
                    if (overrideElement) {
                        return overrideElement
                    } else if (defaultElement) {
                        return defaultElement
                    }
                }
                // no component found, fallback to placeholder
                return PlaceholderElement
            }
        },
        elementClass() {
            return (element) => {
                const classes = [
                    `element-${ element.surveyElementId }`
                ]

                if (element.questionType) {
                    classes.push(`element-question-${ element.questionType }`)

                    if (element.required && !element.settings.hideRequiredLabel) {
                        classes.push('question-required')
                    }
                }
                
                return classes
            }
        },
        showConditional() {
            const answers = this.answers

            return (element) => validateConditions(element.conditions, answers)
        },
        productsSummary() {
            const answers = this.answers
            
            return Object.values(answers)
                .filter(row => row.type === 'products')
                .map(answer => {                    
                    if (!answer || !answer.value) return 0;
                    
                    const { prices, specifyingField } = answer.extra
                    const productsSum = answer.value.map(productId => {
                        const sum = prices[productId] * specifyingField[productId]
                        
                        return isFinite(sum) ? sum : 0
                    })
                    
                    return productsSum.reduce((acc, curr) => acc + curr, 0)
                }).reduce((acc, curr) => acc + curr, 0)
        },
        sendButtonText() {
            const { settings } = this.form

            if (settings.sendButton) {
                return settings.sendButton
            }

            return this.$_locale('send')
        },
    },
    watch: {
        productsSummary(val) {
            console.log('products summary change!', val)
            this.$emit('priceSummaryChange', val)
        }
        /*
        productsSummary: {
            deep: true,
            immediate: true,
            handler(val) {
                console.log('products summary change!', JSON.parse(JSON.stringify(val)))
            }
        },
        */
    },
    methods: {
        onValueChange({ questionId, value, extra, questionType }) {
            let id = `q-${ questionId }`
            const flatDataElements = ['contactDetails','companyDetails','billingDetails','consent']
            const old = this.answers[id]
            const newAnswer = {
                ...old,
                value,
                extra, 
                type: questionType
            }

            if (flatDataElements.includes(questionType)) {
                delete newAnswer.value

                Object.keys(value).forEach(key => {
                    newAnswer[key] = value[key]
                })

                id = questionType
            }

            // Vue 2 support needs to use $set with objects
            if (typeof this.$set === 'function') {
                this.$set(this.answers, id, newAnswer)
            } else {
                this.answers[id] = newAnswer
            }
        },
        processAnswers() {
            const { settings } = this.form
            const answers = this.answers
            const elements = this.elements
            const pages = this.pages
            const activePage = this.activePage
            const errors = {}
            const validAnswers = validate(pages[activePage], answers, errors)

            this.errors = errors
            
            if (validAnswers === false) {
                return false;
            }
            
            handleRepeaterAnswers(answers)
            removeHiddenAnswers(elements, answers, false)
            handleMatchAnswers(answers, (game) => {
                const startTime = this.$_formatDateTime(this.$_dateFormat.DATETIME_DMYHI, game.activityStartTime);
                return {
                    value: game.activityId,
                    text: `${ game.homeTeam } - ${ game.awayTeam } (${ startTime })`
                }
            })

            const endTime = getTimestamp()
            
            if (settings.responseActivityId) {
                answers.responseActivityId = settings.responseActivityId;
            }                

            return {
                answers,
                startTime: this.startTime,
                endTime,
            }
        },
        save() {
            const answers = this.processAnswers()
            
            this.$emit('done', answers)
        },
        changePage(direction) {
            const answers = this.answers
            const pages = this.pages
            const activePage = this.activePage
            const errors = {}
            const validAnswers = validate(pages[activePage], answers, errors)

            this.errors = errors
            
            if (direction > 0 && validAnswers === false) {
                return false;
            }

            this.activePage += direction
            this.$nextTick(() => {
                const { surveyMain } = this.$refs
                const bounding = surveyMain.getBoundingClientRect()

                window.scrollTo(0, bounding.y)
            })
        },
        warmpUpAnswers() {
            const questions = this.elements
                .filter(row => row.type === 'question')
            const nested = this.elements.filter(
                row => Array.isArray(row.elements) && row.elements.length > 0
            ).reduce((acc, curr) => {
                return [
                    ...acc,
                    ...curr.elements.filter(row => row.type === 'question')
                ]
            }, [])

            const questionIds = [
                ...questions,
                ...nested,
            ]
            
            questionIds.forEach(row => {
                const { 
                    questionId, 
                    questionType,
                } = row
                const id = `q-${ questionId }`
                let type = questionType

                if (questionType === 'textfield') {
                    type = row.fieldType
                }

                const answer = {
                    questionId,
                    value: null,
                    type,
                    extra: {}
                }
                const error = {
                    show: false,
                    message: ''
                }
                
                // Vue 2 support needs to use $set with objects
                if (typeof this.$set === 'function') {
                    this.$set(this.answers, id, answer)
                    this.$set(this.errors, questionId, error)
                } else {
                    this.answers[id] = answer;
                    this.errors[questionId] = error;
                }
            })

            const [ contactDetails ] = this.elements.filter(row => row.type === 'contactDetails')

            if (contactDetails) {
                const { options } = contactDetails
                const answer = {
                    type: 'contactDetails',
                    firstName: '', 
                    familyName: '', 
                    email: ''
                }

                options.forEach(option => {
                    if (option === 'address') {
                        answer['streetAddress'] = ''
                        answer['city'] = ''
                        answer['postalCode'] = ''
                    } else if (option === 'gender') {
                        answer['gender'] = ''
                    } else {
                        answer[option] = ''
                    }
                })

                // Vue 2 support needs to use $set with objects
                if (typeof this.$set === 'function') {
                    this.$set(this.answers, 'contactDetails', answer)
                } else {
                    this.answers['contactDetails'] = answer;
                }
            }
        },
    },
    mounted() {
        this.elements = this.form.elements
        this.startTime = getTimestamp()

        this.$nextTick(() => {
            this.warmpUpAnswers()
            
            if (this.injectAnswers) {
                doAnswerInjection(
                    this.injectAnswers, 
                    this.elements, 
                    this.answers
                )
            }
        })
    },
}
</script>

<style>
.survey-conditionalarea,
.survey-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.survey-conditionalarea .survey-element,
.survey-page .survey-element {
    width: 100%;
}

.survey-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* linearscale */
.element-question-linearscale .survey-linearscale__question-on-side {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.element-question-linearscale .survey-linearscale__question-on-side .survey-comments {
    width: 100%;
}

.element-question-linearscale .survey-linearscale__question-on-side > label {
    width: 30%;
}

.element-question-linearscale .survey-linearscale__question-on-side .survey-input-container {
    flex-grow: 1;
}

.element-question-linearscale .survey-input-container {
    display: flex;
    flex-direction: row;
}

.element-question-linearscale .survey-input-container .survey-linearscale__first-label,
.element-question-linearscale .survey-input-container .survey-linearscale__second-label {
    width: 20%;
}

.element-question-linearscale .survey-input-container .survey-linearscale__scale {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.element-question-linearscale .survey-input-container .survey-linearscale__scale > label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

/* checkradio and products */
.element-question-products .survey-input-container,
.element-question-checkbox .survey-input-container {
    display: flex;
    flex-direction: column;
}

.element-question-products .survey-checkbox__option,
.element-question-products .survey-checkbox__option-else,
.element-question-products .survey-checkbox__option label,
.element-question-checkbox .survey-checkbox__option,
.element-question-checkbox .survey-checkbox__option-else,
.element-question-checkbox .survey-checkbox__option label {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    justify-content: flex-start;
}

/* question extra comments element */
.survey-comments label {
    display: block;
}

/* contactdetails */
.survey-contactdetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.survey-contactdetails > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.survey-contactdetails hr {
    width: 100%;
}

.survey-contactdetails > .survey-contactdetails__firstName,
.survey-contactdetails > .survey-contactdetails__familyName {
    width: 50%;
}
</style>