import axios from 'axios'
import { param } from './helpers.js'
const CancelToken = axios.CancelToken;
const getReservations_source = CancelToken.source();
let settings = {
    baseUrl: null, 
    restUrl: null, 
    ecomUrl: null,
    ajaxUrl: null,
    adminUrl: null,
    appauth: null,
};

export default {
    setSettings({ baseUrl, restUrl, ecomUrl, ajaxUrl, adminUrl, appauth }) {
        settings = { baseUrl, restUrl, ecomUrl, ajaxUrl, adminUrl, appauth };
    },
    getSettings() {
        return settings;
    },

    removeFromCart(cartProductId) {
        return axios({
            method: 'POST',
            url: settings.restUrl + '/carts/my/products/' + cartProductId,
            withCredentials: true,
            data: {
                quantity: 0
            }
        })
    },
    
    deleteCartOrder() {
        return axios({
            method: 'DELETE',
            url: settings.restUrl + '/carts/my/order',
            withCredentials: true
        })
    },
    
    createMockReservation(data) {
        const status = 2;
        
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/reservations/order/${ status }`,
            withCredentials: true,
            data
        })
    },

    activateOrder(orderId, hash) {
        const params = {
            orderId,
            hash,
        };
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: `${ settings.ecomUrl }?action=activateorder${ queryString }`,
        })
    },

    getPaymentMethods() {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }/?getpaymentmethods=1${ queryString }`
        })
    },

    getAvailablePaymentMethods(start, end, productids = []) {
        const params = {
            start,
            end,
            productids
        }
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }/?publicpaymentmethods=1${ queryString }`
        })
    },

    // this is the old checkout no 5
    invoiceConfirmation(url, adminMode = false) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (adminMode) {
            params.noreceipt = 1;
        }

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.ecomUrl }${ url }${ queryString }`,
        })
    },

    getOrderInformation(orderId) {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/orders/${ orderId }`,
            withCredentials: true,
        })
    },

    handlePaymentMethod(orderId, selectedPaymentId) {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/orders/${ orderId }/pay/${ selectedPaymentId }`,
            withCredentials: true,
        })
    },

    createOrder(data) {
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/carts/my/checkout`,
            data,
            withCredentials: true,
        })
    },

    initRangeDispencer() {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/iot/rangedispencer/init/`,
            withCredentials: true
        })
    },

    useRangeDispencer(deviceId, accessControlId) {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/iot/rangedispencer/use/?deviceid=${ deviceId }&id=${ accessControlId }`,
            withCredentials: true
        })
    },

    submitGiftCard(data) {
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/carts/my/giftcards/`,
            data,
            withCredentials: true
        })
    },

    submitCoupon(data) {
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/carts/my/coupons/`,
            data,
            withCredentials: true
        })
    },

    useWallet(data) {
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/carts/my/wallet/use`,
            withCredentials: true,
            data,
        })
    },

    getGiftCards() {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/carts/my/giftcards/`,
            withCredentials: true
        })
    },

    getCoupons() {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/carts/my/coupons/`,
            withCredentials: true
        })
    },

    getWallet() {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/carts/my/wallet/`,
            withCredentials: true
        })
    },

    disableTime(data) {
        const status = 4;
        
        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/reservations/order/${status}`,
            withCredentials: true,
            data
        })
    },
    
    disableTimeLegacy(data) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'POST',
            url: settings.ajaxUrl + '?reservations=disabletime' + queryString,
            data
        })
    },
    
    addToCart(data) {
        return axios({
            method: 'POST',
            url: settings.restUrl + '/carts/my/products', //?action=addToCart
            withCredentials: true,
            headers: {
            },
            data
        })
    },
    
    getReservationProducts() {
        return axios({
            method: 'GET',
            url: settings.restUrl + '/products/?select=productid,meta,type,tickettype,price,benefitprice,trackinventory,maximum,visibility,active,manufacturer,dateofpublication,expirationdate,images&type=6',
            withCredentials: true,
        })
    },

    getProductInfo(productId) {
        return axios({
            method: 'GET',
            url: settings.restUrl + '/products/?select=productid,meta,type,price,maximum,visibility,active,manufacturer,images&productid='+productId,
            withCredentials: true,
        })
    },

    getProductUserInfo(productId) {
        const params = {
            productid: productId
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/reservations/initialization/?${ param(params) }`,
            withCredentials: true,
        })
    },
    
    // EcomReservation
    getReservationSettings(productId, date) {
        const params = {
            productid: productId,
            date
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/reservations/calendarsettings/?${ param(params) }`,
            withCredentials: true,
        })
    },

    getReservations(productId, date, weekMode = false, type = 1) {
        const params = {
            productid: productId,
            date
        };
        let queryString = '';
        
        if (weekMode) {
            params.week = 1;
        }

        if (type === 5) {
            params.golf = 1;
        }

        if (Object.keys(params).length > 0) {
            queryString = '?'+param(params);
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/reservations/${ queryString }`,
            withCredentials: true,
            cancelToken: getReservations_source.token
        })
    },
    
    // EcomReservation
    /**
     * 
     * @param {Array} resources - array of ids
     * @param {String} dateTimeStart - format 'YYYY-MM-DD HH:mm'
     * @param {String} dateTimeEnd - format 'YYYY-MM-DD HH:mm'
     * @returns {Promise}
     */
    getResourceReservations(resources, dateTimeStart, dateTimeEnd) {
        const params = {
            resources,
            dateTimeStart,
            dateTimeEnd
        };
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }/?reservations=getresourcereservations${ queryString }`
        })
    },
    
    getMyCart() {
        return axios({
            method: 'GET',
            url: settings.restUrl + '/carts/my/products',
            withCredentials: true,
            headers: {
            }
        })
    },
    
    deactivateReservationTime(reservationTimeId) {
        const params = {
            reservationtimeid: reservationTimeId
        };
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=deactivatereservationtime${ queryString }`
        })
    },
    
    editReservationTime(data) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'POST',
            url: settings.ajaxUrl + '?reservations=edittime' + queryString,
            data
        })
    },
    // lite version of editReservationTime
    moveResourceTime(data) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'POST',
            url: settings.ajaxUrl + '?reservations=moveresource' + queryString,
            data
        })
    },

    getUserGolfReservations(params = {}) {
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=getusergolfreservations${ queryString }`,
        })
    },

    getUserReservations(params = {}) {
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=getuserreservations${ queryString }`,
        })
    },

    confirmTeeTime(reservationTimeId) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: settings.ajaxUrl + '?reservations=confirmgolfteetime&reservationtimeid='+reservationTimeId + queryString
        })
    },

    // when an admin accidentally confirms a tee time, they want to cancel that action, not cancel the tee time
    cancelConfirmedTeeTime(reservationTimeId) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'GET',
            url: settings.ajaxUrl + '?reservations=confirmgolfteetime&cancel=1&reservationtimeid='+reservationTimeId + queryString
        })
    },

    setReservationSaleMode(data) {
        const params = {
            reservations: 'setreservationsalemode',
            appauth: settings.appauth
        };
        return axios({
            method: 'POST',
            url: `${ settings.ajaxUrl }?${ param(params) }`,
            data
        })
    },

    pushResourceComment(data) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '&'+param(params);
        }

        return axios({
            method: 'POST',
            url: settings.ajaxUrl + '?reservations=pushresourcecomment' + queryString,
            data
        })
    },

    testProductOwnership(productId) {
        return axios({
            method: 'GET',
            url: `${ settings.restUrl }/reservations/productownership/?productid=${ productId }`,
            withCredentials: true,
        })
    },

    getShareOwners() {
        const params = {
            shareowners: 1,
            appauth: settings.appauth
        };
        
        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?${ param(params) }`,
        })
    },
    
    requestAccessControl(passcode, personId = null, productId = null) {
        const params = {
            requestaccesscontrol: 1,
            passcode,
            appauth: settings.appauth
        }

        if (personId !== null && productId !== null) {
            params.personid = personId;
            params.productid = productId;
        }
        
        return axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?${ param(params) }`,
        })
    },

    submitCouponCode(code, orderProductId) {
        const data = {
            code,
        }

        return axios({
            method: 'POST',
            url: `${ settings.restUrl }/orders/${ orderProductId }/productcoupons/`,
            data,
            withCredentials: true
        })
    },

    /**
     * @param {String} search
     * @param {Function} callback
     * @returns {Promise} 
     */
    searchPeople(search, companyIds = null) {
        const params = {
            controller: 'admin',
            action: 'listpersonsajax',
            appauth: settings.appauth
        }
        const data = {
            searchTerm: search,
            method: 'AllForNewOrderPage'
        }

        if (companyIds) {
            data.method = 'InCompanies';
            data.companyIds = companyIds;
        }

        return axios({
            method: 'POST',
            url: `${ settings.baseUrl }?${ param(params) }`,
            data,
        })
    },
    
    /**
     * @param {String} search
     * @returns {Promise} 
     */
    searchCompanies(search) {
        const params = {
            controller: 'admin',
            action: 'listcompaniesajax',
            appauth: settings.appauth
        }
        const data = {
            method: 'AllForNewOrderSelectBox2',
            searchTerm: search
        }
        return axios({
            method: 'POST',
            url: `${ settings.baseUrl }?${ param(params) }`,
            data,
        })
    },
}