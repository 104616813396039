import { Preferences } from '@capacitor/preferences';
import store from '@/store';
import settings from '@/WiseSettings';
import { defaultGuard } from '../../../router/guard';

const { commit, dispatch } = store;

export default [
    {
        path: 'welcome',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront/WelcomePage.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: terms } = await Preferences.get({ key: `terms_accepted-${ settings.appId }` });

            if (access_token && terms) {
                next('/golf/front')
            } else {
                next()
            }
        },
    },
    {
        name: 'GolfFront',
        path: 'front',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront/FrontPage.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: terms } = await Preferences.get({ key: `terms_accepted-${ settings.appId }` });

            if (!access_token || !terms) {
                next('/golf/welcome')
            } else {
                await defaultGuard(to, from, next)
            }
        },
    },
    /*{
        path: 'front',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront.vue')
    },*/
    {
        path: 'golfpistelogin',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfPisteLogin.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: isGolfPisteToken } = await Preferences.get({ key: `isGolfPisteToken` })
            console.log('golfpistelogin beforeEnter', access_token, isGolfPisteToken)
            
            if (!isGolfPisteToken && access_token) {
                await Preferences.remove({ key: `access_token-${ settings.appId }` })
            }
            /*
            const loggedIn = store.getters['user/loggedIn']
            console.log('loggedIn', loggedIn)
            // in golfpiste login we need the user to get rid of the possible original session
            // because golfpiste sessionToken takes over
            if (loggedIn) {
                await store.dispatch('user/logOut')
            }
            */
            next()
        }
    },
    {
        path: 'proxy',
        component: {
            template: '<h1></h1>',
            mounted() {
                console.log('proxy mounted!')
            }
        },
        beforeEnter: async (to, from, next) => {
            const { 
                session_token,
                club_id,
                route
            } = to.query
            const hosts = settings.hosts
            const [ selected ] = hosts.filter(row => row.golfClubId == club_id)
            console.log(
                session_token,
                club_id,
                route,
                selected
            )
            window.forceGolfConsent = true;
            await Preferences.set({ key: `access_token-${ settings.appId }`, value: session_token })
            await Preferences.set({ key: `selectedGolfClub-${ settings.appId }`, value: JSON.stringify(selected.name) })
            /*
            if (route.includes('proxy')) {
                next()
            } else {
                next(route)
            }
            */
            next()
        },
    },
    {
        path: 'reservation/:productId',
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationProduct.vue')
    },
    {
        path: 'teetime/:productId/:resourceId',
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationProduct.vue'),
        beforeEnter: async (to, from, next) => {
            const { 
                sessionToken,
                clubId,
                returnUri
            } = to.query

            if (sessionToken && clubId) {
                const hosts = settings.hosts
                const [ selected ] = hosts.filter(row => row.golfClubId == clubId)
                console.log(
                    sessionToken,
                    clubId,
                    selected
                )
                window.forceGolfConsent = true;
                await Preferences.set({ key: `access_token-${ settings.appId }`, value: sessionToken })
                await Preferences.set({ key: `selectedGolfClub-${ settings.appId }`, value: JSON.stringify(selected.name) })
                await dispatch('user/validateAccessTokenFromStorage')
                
                if (returnUri) {
                    await Preferences.set({ key: `third-party-returnURI`, value: returnUri })
                }
            }
            
            next()
        },
    },
    {
        name: 'ReservationsViewer',
        path: 'reservationsviewer/:clubId/:productId', // to be deprecated, used as infotv view..
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationProduct.vue'),
        beforeEnter: async (to, from, next) => {
            const { clubId } = to.params;
            const { sessionToken } = to.query
            const hosts = settings.hosts
            const [ selected ] = hosts.filter(row => row.golfClubId == clubId)
            console.log(clubId, selected)
            // in the case of invalid host, redirect to the front page
            if (!selected) {
                next('/');
            }
            
            if (sessionToken) {
                await Preferences.set({ key: `access_token-${ settings.appId }`, value: sessionToken })
            } else {
                await Preferences.remove({ key: `access_token-${settings.appId}` })
            }
            
            // this has bugs, maybe it should check the session or something..
            // this should support viewing the calendar without logging in
            try {
                [
                    document.querySelector('html'),
                    document.querySelector('body')
                ].forEach(el => {
                    if (el.classList.contains('wisegolf') === false) {
                        el.classList.add('wisegolf')
                    }
                })

                const infoTVStyles = document.createElement('style')
                infoTVStyles.innerHTML = `
                    .infotv {
                        --scroll-offset: 600px;
                    }
                    .infotv .reservation-control-buttons,
                    .infotv .golf-main_navigation-back,
                    .infotv .time-block.past {
                        display: none;
                    }

                    .infotv ion-alert {
                        top: calc(-100% + var(--scroll-offset)) !important;
                    }
                `;
                
                const parent = document.querySelector('html')
                parent.classList.add('infotv')

                document.body.append(infoTVStyles)
                /*
                window.addEventListener('message', (e) => {
                    try {
                        const data = JSON.parse(e.data);

                        if (typeof data === 'object' && data !== null) {
                            console.log('message', data);
                            if (data.type === 'parentscroll') {
                                let offset = data.scrollTop + 600

                                if (offset < 600) {
                                    offset = 600;
                                }

                                parent.style.setProperty('--scroll-offset', offset + 'px')
                            }
                        }
                    } catch(e) {
                        console.log(e)
                    }
                })
                */
                const serviceSettings = {
                    baseUrl: selected.baseUrl,
                    restUrl: selected.restUrl,
                    ecomUrl: selected.ecomUrl,
                    ajaxUrl: selected.ajaxUrl,
                    adminUrl: selected.adminUrl,
                    appauth: selected.appauth,
                }
                
                await Preferences.set({ key: `selectedHost-${settings.appId}`, value: selected.name });
                
                commit('common/mutateSelectedHost', selected.name)
                commit('res_common/setIsEmbed', true)
                dispatch('res_ecom/setEcomServiceSettings', serviceSettings)
                
                await dispatch('user/validateAccessTokenFromStorage')
            } catch(e) {
                console.log(e)
            }
            
            next()
        },
    },
    {
        path: 'reservation',
        component: () => import('@/WiseEcom/EcomReservation/views/ListReservationProducts.vue')
    },
    {
        path: 'event/:activityId',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfEvent.vue')
    },
    {
        path: 'event/:activityId/enroll',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfEventEnroll.vue')
    },
    {
        path: 'rangedispencer',
        component: () => import('@/WiseEcom/EcomReservation/views/RangeDispencer.vue')
    },    
    {
        path: 'account/golfreservations',
        component: () => import('@/WiseEcom/EcomReservation/views/ListGolfReservations.vue')
    },
    {
        path: 'account/couponscodes',
        component: () => import('@/WiseEcom/EcomReservation/views/CustomerCouponsCodes.vue')
    },
    {
        path: 'account/coupons',
        component: () => import('@/WiseEcom/EcomReservation/views/CustomerCoupons.vue')
    },
    {
        path: 'account/membershipcode',
        component: () => import('@/WiseEcom/EcomReservation/views/RegisterMembershipCode.vue')
    },
    {
        path: 'account/reservationsettings',
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationSettings.vue')
    },
    {
        path: 'account/ebirdiesettings',
        component: () => import('@/WiseEcom/EcomReservation/views/EBirdieSettings.vue')
    },
    {
        path: 'services',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfServices.vue')
    },
]