<template>
<div class="survey-comments">
    <label :for="`question-comments-${ element.questionId }`">
        {{ element.settings.extra.comments.title }}
    </label>
    <textarea 
        :id="`question-comments-${ element.questionId }`" 
        @input="onValueChange">
    </textarea>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onValueChange(evt) {
            const { value } = evt.target

            this.$emit('valueChange', { value })
        },
    },
}
</script>