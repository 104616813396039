import moment from 'moment'
import { 
    validateResourceRule,
    timeBlocksCollide,
    serviceFactory,
    //toTimestamp,
    getDuration,
    getBlockIndex,
    CALENDAR_TYPE,
    //TIMETABLE_TYPE,
    TIMEBLOCK_TYPE
} from './ReservationService.js'
import {
    playerMainMembership,
    playerMembershipAbbr,
    playerMembershipColor
} from './helpers.js'
import { formatCurrency } from '../../services/WiseCurrency.js'

const resetTime = { hour: 0, minute: 0, second: 0, millisecond: 0 };
const settingsFactory = () => ({
    'reservationId':null,
    'productId':null,
    'type':3,
    'recurrence':null,
    'recurrenceDays':[true,true,true,true,true,true,true],
    'startDate':null,
    'endDate':null,
    'startTime':null,
    'endTime':null,
    'duration':null,
    'break':null,
    'leadTime':null,
    'quantity':null,
    'minimumOrderQuantity':null,
    'minDuration':null,
    'maxDuration':null,
    'hideQuantity':null,
    'hideEndTime':null,
    'limitFutureReservations':null,
    'disallowReservationsWithin': null,
    'minDurationBetweenReservations':null,
    'groupResourcesAsProducts': null,
    'resources':[],
    'additionalResources':[]
})
const stateFactory = () => ({
    refreshed: null,
    calendarType: null,
    disabled: false,
    dateChangeInProgress: false,
    isInvalidDate: false,
    weekMode: false,
    settings: settingsFactory(),
    reservations: [],
    recurrenceDays: [],
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
    startDateMoved: false,
    selectedDate: moment(),
    activeWeek: null,
    resourceRules: [],
    resourceComments: [],
    additionalResources: [],
    reservationsAdditionalResources: [],
    userReservations: [],
    reservationStatuses: {},
    reservationsInCart: [],
    paymentDelayed: [],
    statusString: '',
})
let service = null;

export default {
    namespaced: true,
    state: stateFactory(),
    getters: {
        CALENDAR_TYPE: () => CALENDAR_TYPE,
        TIMEBLOCK_TYPE: () => TIMEBLOCK_TYPE,
        reservations: (state) => state.reservations,
        reservationsAdditionalResources: (state) => {
            return (filter) => state.reservationsAdditionalResources.filter(filter)
        },
        calendarType: (state) => state.calendarType,
        date: (state) => state.selectedDate,
        dateRestrictions: (state) => {
            return {
                start: state.startDate ? state.startDate.set(resetTime) : null,
                end: state.endDate ? state.endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }) : null
            }
        },
        dayVisibilityLimitations(state) {
            const calendarVisibility = state.resourceRules
                .filter(row => validateResourceRule(row, moment()))
                .filter(row => row.ruleName === 'kalenteriNakyvyysPaivat' && row.isUserRule)
            const limitFutureReservations = state.settings.limitFutureReservations;
            
            if (calendarVisibility.length > 0) {
                const ruleValue = calendarVisibility.reduce(
                    (prev, curr) => curr.ruleValue > prev ? curr.ruleValue : prev,
                    0
                );
                return ruleValue;
            } else if (typeof limitFutureReservations === 'number' && limitFutureReservations !== 0) {
                return limitFutureReservations;
            }

            return 365; // by default you'll see a year ahead
        },
        isInvalidDate: (state, getters, rootState, rootGetters) => (date, debug = false) => {
            const weekCopy = [...state.recurrenceDays];
            const sunday = weekCopy.pop();
            let disabled = false;
            
            if (!rootGetters['res_common/adminMode']) {
                const days = Math.round(moment.duration(date.set(resetTime) - moment().set(resetTime)).asDays());
                const calendarVisibility = state.resourceRules
                    .filter(row => validateResourceRule(row, moment()))
                    .filter(row => row.ruleName === 'kalenteriNakyvyysPaivat' && row.isUserRule)
                const limitFutureReservations = state.settings.limitFutureReservations;

                if (calendarVisibility.length > 0) {
                    const ruleValue = calendarVisibility.reduce(
                        (prev, curr) => curr.ruleValue > prev ? curr.ruleValue : prev,
                        0
                    );

                    if (days > ruleValue) {
                        disabled = true;
                    }
                } else if (typeof limitFutureReservations === 'number' && limitFutureReservations !== 0) {
                    if (days > limitFutureReservations) {
                        disabled = true;
                    }
                }

                if (days < 0) {
                    disabled = true;
                }
            }
            
            if ((!!state.startDate && date.isBefore(state.startDate, 'day')) 
                || (!!state.endDate && date.isAfter(state.endDate, 'day'))) {
                disabled = true;
            }
            
            weekCopy.unshift(sunday);
            weekCopy.forEach((day, i) => {
                if (day === false && date.day() === i) {
                    disabled = true;
                }
            })
            if (debug) console.log(disabled)
            return disabled;
        },
        weekMode: (state, getters, rootState, rootGetters) => {
            const timeTable = rootGetters['res_calendar/timeTable'];
            
            // this is used here for prevent problems during
            // the calendar weekmode change process
            if (timeTable.length > 0 && !!timeTable[0].days) {
                return true;
            }
            
            return state.weekMode;
        },
        resourceRules: (state) => state.resourceRules.filter(row => validateResourceRule(row, state.selectedDate)),
        resourceComments: (state) => state.resourceComments,
        resourceCommentsByStartTime: (state) => (startTime, resourceId) => state.resourceComments
            .filter(row => row.resourceId === resourceId)
            .filter(row => row.dateTimeStart === startTime),
        resourceCommentsByBlockIndex: (state) => {
            //const { settings } = service;
            //const { startTime, duration } = settings;

            return (startIndex, resourceId) => state.resourceComments
            .filter(row => row.resourceId === resourceId)
            .filter(row => {
                //const start = Date.now()
                //const startIndex = getBlockIndex(blockStart, startTime, duration)

                const collision = timeBlocksCollide(
                    row.startIndex,
                    row.endIndex,
                    startIndex,
                    startIndex
                )
                //console.log('comments perf', Date.now() - start)
                return collision;
            })
        },
        resourceCommentsByBlock: (state, getters, rootState, rootGetters) => {
            const adminMode = rootGetters['res_common/adminMode'];

            return (block) => state.resourceComments.filter(row => {
                if (typeof block === 'undefined') return false;
                if (!row.public && !adminMode) return false;
                const { blockId } = block;
                const [ resourceId, blockIndex ] = blockId.split('-');
                const startIndex = parseInt(blockIndex);

                if (resourceId != row.resourceId) return false;
                
                // if comment validity collides with the block, return it
                const collision = timeBlocksCollide(
                    row.startIndex,
                    row.endIndex,
                    startIndex,
                    startIndex
                )
                
                return collision;
            })
        },
        generateReservation: () => (index, table, config = {}) => service.generateReservation(index, table, config),
        service: () => service,
        settings: (state) => state.settings,
        startTime: (state) => state.startTime,
        endTime: (state) => state.endTime,
        startDate: (state) => state.startDate,
        endDate: (state) => state.endDate,
        startDateMoved: (state) => state.startDateMoved,
        disabled: (state) => state.disabled,
        dateChangeInProgress: (state) => state.dateChangeInProgress,
        additionalResources: (state) => state.additionalResources,
        userReservations: (state) => state.userReservations,
        reservationStatus: (state) => (reservationTimeId) => state.reservationStatuses[reservationTimeId],
        reservationPaymentDelayed: (state) => (reservationTimeId) => state.paymentDelayed.includes(reservationTimeId),
        reservationInCart: (state) => (reservationTimeId) => state.reservationInCart.includes(reservationTimeId),
        refreshed: (state) => state.refreshed,
        getReservation: (state) => {
            const reservations = state.reservations;

            return (reservationTimeId) => {
                const [ additionalFound ] = state.reservationsAdditionalResources.filter(row => row.reservationTimeId == reservationTimeId)

                if (additionalFound) {
                    return {
                        ...additionalFound,
                        duration: getDuration(
                            additionalFound.dateTimeStart,
                            additionalFound.dateTimeEnd
                        )
                    }
                }

                const [ reservationFound ] = reservations.filter(row => row.reservationTimeId == reservationTimeId)

                if (reservationFound) {
                    return {
                        ...reservationFound,
                        duration: getDuration(
                            additionalFound.start,
                            additionalFound.end
                        )
                    }
                }

                return null;
            }
        },
        orderAdditionalReservations: (state) => {
            return (orderId) => state.reservationsAdditionalResources.filter(row => row.orderId == orderId)
        },
    },
    mutations: {
        reset(state, { keepDate }) {
            const defaults = stateFactory()

            Object.keys(defaults).forEach(key => {
                if (keepDate && key === 'selectedDate') return;

                const value = defaults[key];

                state[key] = value;
            })
        },
        setSelectedDate(state, value) {
            if (typeof value === 'string') {
                state.selectedDate = moment(value).set({ hours:0,minutes:0,seconds:0 })
            } else {
                state.selectedDate = value
            }
        },
        setServiceAdminMode(state, value) {
            service.settings.admin = value;
        },
        setDisabled(state, value) {
            state.disabled = value;
        },
        setWeekMode(state, value) {
            state.weekMode = value;
        },
        toggleWeekMode(state, value) {
            if (typeof value !== 'undefined') {
                state.weekMode = value;
            } else {
                state.weekMode = !state.weekMode;
            }
            // localstorage should remember the state by productId
            //localStorage['reservationAdminMode'] = JSON.stringify(state.adminMode);
        },
        setResourceRules(state, rows) {
            const ids = state.resourceRules.map(row => row.ruleId);
            const newRows = rows.filter(row => ids.indexOf(row.ruleId) === -1);
            
            state.resourceRules = [
                ...state.resourceRules,
                ...newRows,
            ];
        },
        updateResourceRule(state, data) {
            const { ruleId, usableQuantity } = data;
            const rule = state.resourceRules.filter(row => row.ruleId == ruleId)[0]

            if (!rule) return;

            rule.usableQuantity = usableQuantity;
        },
        setResourceComments(state, rows) {
            const { settings } = service;
            const { startTime, duration } = settings;
            // calculate start and end indexes based on duration settings and time
            // maybe in reservationservice
            state.resourceComments = rows.map(row => {
                row.startIndex = getBlockIndex(row.dateTimeStart, startTime, duration)
                row.endIndex = getBlockIndex(row.dateTimeEnd, startTime, duration) - 1
                
                return row;
            })
        },
        setAdditionalResources(state, rows) {
            state.additionalResources = rows.filter(row => {
                // to ease product creator's life
                if (!row.productId) {
                    console.error(`Additional resource, resourceId: ${ row.resourceId } is missing a productId!`)
                }
                
                return !!row.productId;
            })
        },
        setUserReservations(state, rows) {
            state.userReservations = rows;
        },
        setReservationStatus(state, { reservationTimeId, status }) {
            state.reservationStatuses[reservationTimeId] = status;
        },
        setReservationsAdditionalResources(state, rows) {
            state.reservationsAdditionalResources = rows
            .map(row => {
                row.resourceCategory = row.resourceCategory || '';

                if (row.resourceCategory.includes('-')) {
                    // eslint-disable-next-line no-unused-vars
                    const [ type, number ] = row.resourceCategory.split('-')
                    
                    row.resourceNumber = number;
                }

                row.inFuture = moment(row.dateTimeStart) > moment()

                return row;
            })
        },
    },
    actions: {
        init({ state, commit, /*dispatch,*/ rootGetters }) {
            const product = rootGetters['res_ecom/product'];
            const admin = rootGetters['res_common/adminMode'];
            const settings = state.settings;

            service = serviceFactory({
                product,
                settings,
                selectedDate: state.selectedDate,
                reservations: state.reservations,
                admin,
                resourceRules: state.resourceRules,
            });
            
            state.calendarType = settings.type;
            
            if ([CALENDAR_TYPE.weekCalendar,CALENDAR_TYPE.resource].includes(settings.type)) {
                //$('.calendar-table-info').removeClass('hidden');
            }
            
            if (settings.type === CALENDAR_TYPE.weekCalendar) {
                commit('setWeekMode', true)
            } else if (settings.type === CALENDAR_TYPE.grid) {
                commit('res_calendar/setViewMode', 'grid', { root: true });
            } 
            //else if (settings.type === CALENDAR_TYPE.resource) {}
            /*
            const baseTimeTable = service.createDayTimeBlocks(state.selectedDate, state.startTime, state.endTime);
            const timeTable = service.data;
            
            return dispatch('res_calendar/initTimeTables', { baseTimeTable, timeTable }, { root: true });
            */
           return Promise.resolve()
        },
        updateService({ state, dispatch, rootGetters }) {
            service.settings.resources = state.settings.resources;
            service.settings.reservations = state.reservations;
            service.init();
            let baseTimeTable = [];
            
            if (state.settings.type !== CALENDAR_TYPE.golf) {
                if (rootGetters['res_calendar/baseTimeTable'].length === 0) {
                    baseTimeTable = service.createDayTimeBlocks(state.selectedDate, state.startTime, state.endTime);
                } else {
                    baseTimeTable = rootGetters['res_calendar/baseTimeTable']
                }
            }
            
            const timeTable = service.data;
            
            return dispatch('res_calendar/initTimeTables', { baseTimeTable, timeTable }, { root: true })
        },
        getReservationSettings({ state, getters, commit, /*dispatch,*/ rootGetters }, args) {
            const { productId, date, firstLoad = false } = args;
            const EcomService = rootGetters['res_ecom/EcomService'];
            //const admin = rootGetters['res_common/adminMode'];
            
            return EcomService.getReservationSettings(
                productId, 
                date.format('YYYY-MM-DD'),
                state.weekMode
            ).then((response) => {
                const { data } = response;

                if (data.success === false) {
                    return Promise.reject(data.error)
                }

                const settings = data.reservationSettings;
                const { productName, price } = settings;
                const product = {
                    productId,
                    name: productName,
                    price,
                    type: 6
                }
                                    
                state.settings = settings;
                state.recurrenceDays = settings.recurrenceDays;
                state.startTime = settings.startTime;
                state.endTime = settings.endTime;

                commit('res_ecom/setActiveProduct', product, { root: true })
                commit('setAdditionalResources', settings.additionalResources);
                /*
                if (typeof data.golfClubId !== 'undefined') {
                    commit('res_golf/setDefaultClubId', data.golfClubId, { root: true });
                }
                */
                if (typeof data.resourceRules !== 'undefined') {
                    const resourceRules = data.resourceRules;
                    const handicapDefaults = resourceRules.filter(row => 
                        row.ruleName === 'tasoitusraja' 
                        && typeof row.ruleValue === 'object' 
                        && typeof row.ruleValue.default === 'number'
                    );
                    
                    commit('setResourceRules', resourceRules);
                    
                    if (handicapDefaults.length > 0) {
                        commit('res_golf/setDefaultHandicaps', handicapDefaults, { root: true })
                    }
                }

                if (Array.isArray(data.golfProducts)) {
                    commit(
                        'res_golf/setGolfProducts', 
                        data.golfProducts,
                        { root: true }
                    )
                }
                
                if (firstLoad) {

                    if (settings.startDate) { 
                        state.startDate = moment(`${ settings.startDate } ${ settings.startTime }`);
                    } else {
                        state.startDate = moment();
                    }

                    if (settings.endDate) {
                        state.endDate = moment(`${ settings.endDate } ${ settings.endTime }`);
                    }
                    
                    if (state.startDate >= moment()) {
                        state.selectedDate = state.startDate
                    }
                    
                    state.activeWeek = state.selectedDate.week();
                    state.isInvalidDate = getters.isInvalidDate(state.selectedDate)
                    
                    /*
                    if(!!settings.disallowReservationsWithin && !admin) {
                        state.selectedDate = moment().add(settings.disallowReservationsWithin, 'hours');
                        state.startDate = state.selectedDate;
                    }
                    */
                    if (state.startDate > moment()) {      
                        console.log('start date in the future', state.startDate.format('YYYY-MM-DD'))    
                        state.startDateMoved = true;              
                        return {
                            date: state.startDate,
                        }
                    }
                }

                return {
                    date,
                };
            })
        },
        getReservations({ state, commit, dispatch, rootGetters }, args) {
            const { date, force } = args;
            const admin = rootGetters['res_common/adminMode'];
            const product = rootGetters['res_ecom/product'];
            const EcomService = rootGetters['res_ecom/EcomService'];
            
            if (!product || !product.productId) {
                return Promise.resolve()
            }

            return EcomService.getReservations(
                product.productId, 
                date.format('YYYY-MM-DD'),
                state.weekMode,
                state.settings.type
            ).then((response) => {
                const { data } = response;
                
                if (Array.isArray(data.rows) === false) {
                    return;
                }

                const oldStatusString = state.statusString;
                const statusString = data.rows.map(row => `${ row.reservationTimeId }-${ row.status }`).join('|');
                //console.log('reservationCount', {force}, state.reservations.length, data.rows.length)
                data.rows.forEach(({ reservationTimeId, status }) => commit('setReservationStatus', { reservationTimeId, status }));
                
                state.statusString = statusString;
                state.paymentDelayed = data.rows.filter(row => row.orderStatusId != 2).map(row => row.reservationTimeId)
                state.reservationInCart = data.rows.filter(row => row.isCart).map(row => row.reservationTimeId)
                state.refreshed = Date.now();

                if (Array.isArray(data.resourceComments)) {
                    commit('setResourceComments', data.resourceComments);
                }

                if (Array.isArray(data.reservationsAdditionalResources)) {
                    commit('setReservationsAdditionalResources', data.reservationsAdditionalResources)
                }
                
                // CALENDAR OPTIMIZATION - check if anything really changes in main reservations
                if (!force && state.reservations.length === data.rows.length && statusString === oldStatusString) {
                    return Promise.resolve();
                }
                
                state.reservations = data.rows;
                
                if (Array.isArray(data.reservationsGolfPlayers)) {
                    const reservationsGolfPlayers = data.reservationsGolfPlayers
                    .map(row => {
                        const [ reservation ] = data.rows.filter(res => res.reservationTimeId == row.reservationTimeId)
                        if (typeof row.accessControlCategories === 'string') {
                            row.accessControlClass = row.accessControlCategories.split('|')
                                .map(row => `person-membership-${ row.toLowerCase().replaceAll(" ","-") }`)
                        }
                        
                        row.formattedHandicap = formatCurrency(row.handicapActive, true, 1);
                        row.membership = playerMainMembership(row, admin)
                        row.membershipAbbr = playerMembershipAbbr(row, admin)
                        row.membershipStyles = playerMembershipColor(row, admin)
                        row.dateTimeStart = reservation.start
                        
                        return row;
                    })
                    
                    commit(
                        'res_golf/setReservationPlayers', 
                        reservationsGolfPlayers, 
                        { root: true }
                    );
                }

                return dispatch('updateService');
            })
        },
        onWeekModeChange({ state, dispatch, rootGetters }) {
            state.dateChangeInProgress = true;
            
            const EcomService = rootGetters['res_ecom/EcomService'];
            const product = rootGetters['res_ecom/product'];
            const admin = rootGetters['res_common/adminMode'];
            const settings = state.settings;
            
            return EcomService.getReservations(
                product.productId, 
                state.selectedDate.format('YYYY-MM-DD'),
                state.weekMode
            ).then((response) => {
                const { data } = response;
                state.reservations = data.rows;
                state.dateChangeInProgress = false;

                if (state.weekMode) {
                    settings.type = CALENDAR_TYPE.weekCalendar;
                } else {
                    settings.type = CALENDAR_TYPE.resource;
                }

                service = serviceFactory({
                    product,
                    settings,
                    selectedDate: state.selectedDate,
                    reservations: state.reservations,
                    admin,
                    resourceRules: state.resourceRules,
                });
            
                return dispatch('updateService')
            })
        },
        onDateChange({ state, getters, dispatch }, newDay) {
            //const admin = rootGetters['res_common/adminMode'];
            // if there is too early date change
            if (service === null) {
                return Promise.resolve();
            }

            state.dateChangeInProgress = true;
            // for weekCalendar type
            if (state.activeWeek !== newDay.week()) {
                // get reservations and availabilities for new week
                // generate new weekCalendar
                state.activeWeek = newDay.week();
            } else {
                // highlight the day?
            }
            
            service.onDateChange(newDay, state.startTime, state.endTime);
            //service.createDayTimeBlocks(newDay, state.startTime, state.endTime);

            return dispatch('getReservations', { date: newDay, force: true })
                .then(() => {
                    state.selectedDate = newDay;
                    state.isInvalidDate = getters.isInvalidDate(newDay);
                    state.dateChangeInProgress = false;
                })
        },
        deleteResourceComment({ state, rootGetters }, commentData) {
            const EcomService = rootGetters['res_ecom/EcomService'];
            const { productId } = rootGetters['res_ecom/product'];

            return EcomService.pushResourceComment({ 
                productId,
                commentId: commentData.commentId, 
                delete: true 
            })
            .then(response => {
                const { data } = response;

                if (data.success) {
                    const index = state.resourceComments.findIndex(row => row.commentId === commentData.commentId);
                    state.resourceComments.splice(index, 1);
                }

                return response;
            })
        },
        addResourceComment({ state, commit, rootGetters }, commentData) {
            const EcomService = rootGetters['res_ecom/EcomService'];
            const { productId } = rootGetters['res_ecom/product'];

            commentData.productId = productId;

            return EcomService.pushResourceComment(commentData)
                .then(response => {
                    const { data } = response;

                    if (data.success) {
                        if (!commentData.commentId) {
                            const { 
                                commentId, 
                                createdByUserId, 
                                firstName, 
                                familyName 
                            } = data;

                            commit('setResourceComments', [
                                ...state.resourceComments,
                                { 
                                    ...commentData, 
                                    commentId, 
                                    createdByUserId, 
                                    firstName, 
                                    familyName,
                                }
                            ])
                        } else {
                            // update comment
                            const index = state.resourceComments.findIndex(row => row.commentId === commentData.commentId);
                            const comment = state.resourceComments[index];

                            if (typeof comment !== 'undefined') {
                                if (data.deleted) {
                                    state.resourceComments.splice(index, 1);
                                } else {
                                    Object.keys(commentData).forEach(key => {
                                        comment[key] = commentData[key];
                                    })
                                }
                            }
                        }
                    }

                    return response;
                })
        },
        getUserReservations({ commit, rootGetters }, params = {}) {
            const EcomService = rootGetters['res_ecom/EcomService'];
            const product = rootGetters['res_ecom/product'];
            
            params.productid = product.productId;

            return EcomService.getUserReservations(params)
                .then((response) => {
                    const { data } = response;

                    if (Array.isArray(data.rows)) {
                        commit('setUserReservations', data.rows);
                    }
                })
        },
    }
}