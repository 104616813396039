import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import { _locale } from '../../../WiseEcom/services/WiseLocale';

let accessTokenValidTimestamp = null
// Used for to populate user state on initial load and reset user state on logout
const getUserDefaultState = () => {
	return {
		loggedIn: false,
		user: null,
		overrideHostUser: null,
	}
}

export default {
	namespaced: true,
	state: getUserDefaultState(),
	getters: {
		loggedIn: (state) => state.loggedIn,
		user: (state) => state.user,
		activeUser: (state) => {
			if (state.overrideHostUser !== null) {
				return {
					...state.user,
					...state.overrideHostUser,
				}
			}

			return state.user;
		},
	},
	mutations: {
		mutateUserStateToDefault(state) {
			Object.assign(state, getUserDefaultState())
		},
		mutateLoggedInUserData(state, user) {
			if (user === null) console.log(`%cUser data set to:`, 'color: yellow; padding: 5px 0', null)
			state.user = user;
		},
		mutateLoggedIn(state, loggedIn) {
			if (loggedIn === false) {
				console.log(`%cLogged in set to:`, 'color: yellow; padding: 5px 0', false)
			}
			state.loggedIn = loggedIn;
		},
		mutateOverrideHostUser(state, user) {
			state.overrideHostUser = user;
		}
	},
	actions: {
		async validateAccessTokenFromStorage({ dispatch, rootGetters, rootState, commit }) {
			const activeHost = rootGetters["common/activeHost"];
			const { value: accessToken } = await Preferences.get({ key: `access_token-${rootState.common.settings.appId}` });
			let legitToken = false;
			let user = null;
			// If no accessToken
			if (!accessToken) {
				return Promise.reject('No access token found from local storage');
			}
			
			if (activeHost.sessionType) {
				axios.defaults.headers.common['x-session-type'] = activeHost.sessionType;
			}

			// Validate access token if last validation over 5min ago
			if (Date.now() - accessTokenValidTimestamp < 300000) {
				return true;
			}

			accessTokenValidTimestamp = Date.now()

			try {
				const { data } = await validateAccessToken(rootGetters, accessToken)
				
				if (data.user.isGolfpisteSession 
					&& location.href.includes('app.wisegolf.fi')) {
					return false
				}

				legitToken = data.success;
				user = data.user;
			} catch (error) {
				console.error(error)
			}

			if (legitToken) {
				axios.defaults.headers.common['Authorization'] = `token ${ accessToken }`;

				commit('mutateLoggedIn', true)
				commit('mutateLoggedInUserData', user) // Populate user object with logged in user data

				if (rootState.common.settings.useGymFeatures) {
					// Update Door/Location data from server
					await dispatch('door/getDoorAndLocationDataFromServer', {}, {root: true})
				}
			} else {
				commit('mutateLoggedIn', false)
				commit('mutateLoggedInUserData', null)
			}

			return legitToken;
		},
		
		// Update user notification settings to DB
		async updateUserSettings( {state, rootState, rootGetters}, newSettings) {
			try {
				const deviceGetInfo = await Device.getInfo();
				// Map deviceInfo values for backend
				const deviceInfo = {
					uuid: deviceGetInfo.uuid,
					manufacturer: deviceGetInfo.manufacturer,
					model: deviceGetInfo.model,
					platform: deviceGetInfo.operatingSystem,
					version: deviceGetInfo.osVersion
				}

				const settingsData = {
					appId: rootState.common.settings.appId,
					settings: {notifications: newSettings},
					user: state.user,
					device: deviceInfo,
					appVersion: rootState.common.settings.appVersion,
					binaryVersion: null
				}

				await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?updateusersettings=1&appauth=${rootGetters["common/activeHost"].appauth}`, settingsData)
			} catch (error) {
				console.error("Error on updateUserSettings():", error)
			}
		},

		async logIn({ rootGetters, commit, rootState }, loginFormData) {
			const activeHost = rootGetters['common/activeHost'];
			const { settings } = rootState.common;
			const headers = {};
			let loginUrl = `${ activeHost.baseUrl }?action=ajaxauthorize&appauth=${activeHost.appauth}`;

			if (activeHost.restUrl && activeHost.useRestLogin) {
				loginUrl = `${ activeHost.restUrl }/auth`;
			}
			
			if (activeHost.sessionType) {
				axios.defaults.headers.common['x-session-type'] = activeHost.sessionType;
			}

			loginFormData.appId = settings.appId
			loginFormData.version = settings.version
			
			const { data } = await axios.post(
				loginUrl, 
				loginFormData,
				{ headers }
			).catch((error) => {
				// handle error by normalizing app flow
				const { response } = error
				// status 401 is the only planned case from the auth endpoint
				if (response.status === 401) {
					return response
				}
				// mimicing response
				return {
					data: {
						success: false,
						error: _locale('login.serverError')
					}
				}
			})

			if (data.success === false) {
				return data
			}

			axios.defaults.headers.common['Authorization'] = `token ${ data.access_token }`;
			
			commit('mutateLoggedInUserData', data)
			commit('mutateLoggedIn', true)

			try {
				await Preferences.set({ key: `access_token-${ settings.appId }`, value: data.access_token });
				await Preferences.set({ key: `selectedHost-${ settings.appId }`, value: activeHost.name });
			} catch(e) {
				console.log('error while handling access_token preferences')
			}
			
			return data;
		},

		async logOut({ rootState, rootGetters, commit, dispatch }) {
			const { settings } = rootState.common;

			try {
				const originalHost = rootGetters['common/originalHost']
				const url = `${ originalHost.restUrl }/logout`
			
				await axios.post(url)
			} catch (error) {
				console.log(error)
			}

			// Set notifications to disabled (also triggers unsubscribe to all topics by: [pushNotificationsHelper - watch: notificationsEnabled])
			await commit("common/mutateNotificationsEnabled", false, {root: true})

			// Clear fitness data update interval
			clearInterval(rootState.gym_common.updateFitnessDataInterval)

			// Reset User vuex module states
			await commit('mutateUserStateToDefault')
			console.log(`%cVuex user module states reset`, 'color: yellow; padding: 5px 0')

			// Reset Gym common vuex module states
			await commit("gym_common/mutateGymCommonStateToDefault", {}, {root: true})
			console.log(`%cVuex gym common module states reset`, 'color: yellow; padding: 5px 0')

			// Reset Door vuex module states
			await commit("door/mutateDoorStateToDefault", {}, {root: true})
			console.log(`%cVuex door module states reset`, 'color: yellow; padding: 5px 0')

			// Reset common vuex module states
			await commit("common/mutateCommonStateToDefault", {}, {root: true})
			console.log(`%cVuex common module states reset`, 'color: yellow; padding: 5px 0')
			// Get MobileAppSettings back from DB after common state reset
			dispatch("common/getMobileAppSettings", {}, {root: true});

			try {
				delete axios.defaults.headers.common.Authorization

				const userPreferences = await Preferences.keys()
				const skipReset = ['screenMode', 'selectedHost', 'selectedLocale']
				.map(key => `${ key }-${ settings.appId }`)
				
				userPreferences.keys.forEach(async (key) => {
					if (skipReset.includes(key)) return;
					await Preferences.remove({ key })
				})
				console.log(`%cLocal storage cleared!`, 'color: yellow; padding: 5px 0')
				console.log(`%cLogout success!`, 'color: lime; padding: 5px 0')
			} catch (error) {
				console.error("Error clearing local storage: ", error)
			}
		},

		async deleteUser({ rootGetters }) {
			try {
				const activeHost = rootGetters["common/activeHost"];
				const url = `${ activeHost.ajaxUrl }?appdeleteperson=1&appauth=${ activeHost.appauth }`;
			
				const { data } = await axios.post(url);
				
				return data;	

			} catch (error) {
				if (error.response) {
					console.error(error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error(error)
					return Promise.reject(error)
				}
			}
		},

		// special wisegolf endpoint for consenting user to another service provider
		async consentUser({ commit }, host) {
			
			try {
				let loginUrl = `${ host.restUrl }/app/signon/consent${ host.checkConsent ? '/?checkConsent=1' : '' }`;
				
				const { data } = await axios.post(loginUrl);

				if (data.user) {
					commit('mutateLoggedInUserData', data.user)
				}
				
				return data;
			} catch (error) {
				if (error.response) {
					console.error('wisegolf consent error: ', error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error('wisegolf consent error: ', error)
					return Promise.reject(error)
				}
			}
		}
	}
}

const validateAccessToken = async (rootGetters, accessToken) => {
	try {
		return await axios.get(`${rootGetters["common/activeHost"].ajaxUrl}?getsessiondata=1&appauth=${rootGetters["common/activeHost"].appauth}`,
			{
				headers: { 'Authorization': `token ${accessToken}` }
			});
	} catch (error) {
		console.error(error)
	}
}
