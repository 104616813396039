<template>
<div class="survey-image" v-if="element.type === 'image'">
    <img :src="element.imageUrl" />
</div>
<hr class="survey-separator" v-else-if="element.type === 'separator'" />
<p class="survey-paragraph" v-else-if="element.type === 'text'" v-html="element.content"></p>
<h3 class="survey-subheading" v-else-if="element.type === 'subheading'" v-html="element.content"></h3>
<div v-else>{{ element }}</div>
</template>

<script>
export default {
    props: {
        element: {
            type: Object,
            required: true,
        }
    },
}
</script>