<template>
<ion-row>
    <ion-col class="empty-state-placeholder">
        <ion-spinner color="primary" v-if="loading"></ion-spinner>
        <ion-button v-else
            class="circle"
            :disabled="true" 
            size="small">
            <i class="wn-icon" :class="icon" slot="icon-only"></i>
        </ion-button>
        <ion-label>
            <h3 v-if="title" style="text-align: center;">{{ title }}</h3>
            <p v-if="description" style="text-align: center;">{{ description }}</p>
        </ion-label>
    </ion-col>
</ion-row>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>