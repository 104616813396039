<template>
<div class="survey-question"
    :class="{ 'survey-linearscale__question-on-side': element.settings.showQuestionOnTheSide }">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <select v-model="value">
            <option value="">{{ $_locale('select') }}</option>
            <option v-for="option in options"
                :key="`option-${ element.questionId }-${ option.value }`"
                :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </div>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        value: '',
        extra: {
            comments: '',
        },
    }),
    watch: {
        value(value) {
            const { questionId, questionType } = this.element

            this.$emit('valueChange', { questionId, value, extra: this.extra, questionType })
        },
    },
    computed: {
        options() {
            const { settings, options } = this.element

            if (settings.useObjects) {
                return options
            }

            return options.map(row => ({
                text: row,
                value: row
            }))
        },
    },
    methods: {
    },
    mounted() {
        if (this.initialValue) {
            this.value = this.initialValue
        }
    }
}
</script>