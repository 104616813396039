import settings from '@/WiseSettings.js';
import systemNotifications from '@/SystemNotifications.js';
import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import fitnessDefaultImage from '../../../assets/fitness_default_image.jpg'
// Used for to populate common state on initial load and reset common state on logout
const getCommonDefaultState = () => {
	return {
		userLocale: null, // populated from main.js on app init
		settings: settings,
		screenMode: null,
		selectedHost: null,
		overrideHost: null, // wisegolf feature, separate idea from having multiple proper hosts
		loadingArticles: false,
		listArticles: [],
		sliderArticles: [],
		loadMoreArticlesPage: 2,
		articlesLoadedFromHost: null,
		articlesLoadedLocale: null,
		articlesLoadedTimestamp: null,
		consentCheckboxText: null,
		// Push notifications
		notificationsSettings: {
			enabled: true,
			token: "",
			topics: settings.topicDefaults
		},
		notifications: [],
		contracts: [],
		loadingNotifications: false,
		loadingContracts: false,
		commonShowAlert: null, // Alert instance to use onDidDismiss etc... when used this.ShowAlert()
		defaultFitnessImage: fitnessDefaultImage, // Default image for front page enrolled events and possible future workout feature
		// FIXME Add default error image
		errorImage: "https://ionicframework.com/docs/img/demos/thumbnail.svg", // Articles image when error loading image
		// Benefits
		// FIXME Add default benefits image
		defaultBenefitsImage: "https://picsum.photos/id/103/150/150", // If no image on benefits use this
		loadingBenefits: false,
		benefits: [],
		benefitsLoadedLocale: null,
		benefitsLoadedTimestamp: null,
		checkedSystemNotifications: [],
	}
}

export default {
	namespaced: true,
	state: getCommonDefaultState(),
	getters: {
		userLocale: (state) => state.userLocale,
		darkMode: (state) => state.screenMode === 'dark',
		allHosts: (state) => state.settings.hosts,
		selectedHost: (state) => state.selectedHost,
		originalHost: (state) => {
			if (typeof (state.selectedHost) === "object" && state.selectedHost === null || state.selectedHost === '') {
				// If no host selected return first as default
				return state.settings.hosts[0];
			} else {
				const selectedHost = state.settings.hosts.find(host => host.name === state.selectedHost);
				if (!selectedHost) {
					console.log('selected host not found', state.selectedHost)
				}
				return selectedHost || state.settings.hosts[0];
			}
		},
		activeHost: (state, getters) => {
			const { originalHost } = getters;
			
			if (state.overrideHost !== null) {
				return {
					isOverrideHost: true,
					...originalHost,
					...state.overrideHost,
				}
			}

			return originalHost;
		},
		// for shorthand usage
		restUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.restUrl;
		},
		ajaxUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.ajaxUrl;
		},
		baseUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.baseUrl;
		},
		ecomUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.ecomUrl;
		},
		adminUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.adminUrl;
		},
		appauth: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.appauth;
		},
		systemNotifications(state) {
			const checked = state.checkedSystemNotifications;
			
			return (pathName) => {
				const notifications = systemNotifications[pathName];

				if (!Array.isArray(notifications)) return [];

				return notifications
					.filter(row => row.expired === false)
					.filter(row => checked.includes(row.id) === false)
			}
		},
		notificationsSettings: (state) => state.notificationsSettings,
		// NOTE! This differs from the WiseSettings by having the backend override values!
		appSettings: (state) => state.settings,
	},
	mutations: {
		screenMode(state, value) {
			state.screenMode = value;

			if (value === 'dark') {
				document.body.classList.add('dark')
			} else {
				document.body.classList.remove('dark')
			}

			Preferences.set({ key: `screenMode-${settings.appId}`, value })
		},
		mutateCommonStateToDefault(state) {
			const skipReset = ['screenMode', 'selectedHost', 'userLocale']
			const defaultValues = getCommonDefaultState()

			Object.keys(defaultValues).forEach(key => {
				if (skipReset.includes(key)) return;

				state[key] = defaultValues[key]
			})
		},
		mutateNotificationsEnabled(state, notificationsEnabled) {
			state.notificationsSettings.enabled = notificationsEnabled;
			Preferences.set({ key: `notifications-settings-${state.settings.appId}`, value: JSON.stringify(state.notificationsSettings) });
		},
		// Used when user toggle topic enable/disable
		mutateTopicEnabled(state, topic) {
			state.notificationsSettings.topics[topic.key] = topic.enabled
			// Update values in localStorage
			Preferences.set({ key: `notifications-settings-${state.settings.appId}`, value: JSON.stringify(state.notificationsSettings) });
		},
		// Used when found notification settings from localStorage
		mutateNotificationSettings(state, notificationsSettings) {
			state.notificationsSettings = notificationsSettings;
		},
		// Used when mobileAppSettings have defaultTopics
		mutateDefaultTopics(state, defaultTopics) {
			state.notificationsSettings.topics = defaultTopics;
			Preferences.set({ key: `notifications-settings-${state.settings.appId}`, value: JSON.stringify(state.notificationsSettings) });
		},
		mutateFirebaseToken(state, fcmToken) {
			state.notificationsSettings.token = fcmToken
			// Update values in localStorage
			Preferences.set({ key: `notifications-settings-${state.settings.appId}`, value: JSON.stringify(state.notificationsSettings) });
		},

		mutateCommonShowAlert(state, commonShowAlert) {
			state.commonShowAlert = commonShowAlert;
		},
		mutateListArticles(state, listArticles) {
			state.listArticles = listArticles;
		},
		mutateMoreListArticles(state, listArticles) {
			state.listArticles = state.listArticles.concat(listArticles);
		},
		mutateLoadingState(state, loadingArticles) {
			state.loadingArticles = loadingArticles
		},
		mutateSliderArticles(state, sliderArticles) {
			state.sliderArticles = sliderArticles;
		},

		mutateSelectedHost(state, selectedHost) {
			state.selectedHost = selectedHost;
			console.log(`%cSelected host is: ${selectedHost}`, 'color: orange; padding: 5px 0')
		},
		mutateOverrideHost(state, overrideHost) {
			state.overrideHost = overrideHost;
			console.log(`%cSelected override host is: ${overrideHost?.name}`, 'color: lightcoral; padding: 5px 0')
		},

		mutateLoadMoreArticlesPage(state, loadMoreArticlesPage) {
			state.loadMoreArticlesPage = loadMoreArticlesPage;
		},
		mutateArticlesLoadedFromHost(state, articlesLoadedFromHost) {
			state.articlesLoadedFromHost = articlesLoadedFromHost;
		},
		mutateArticlesLoadedLocale(state, articlesLoadedLocale) {
			state.articlesLoadedLocale = articlesLoadedLocale;
		},
		mutateArticlesLoadedTimestamp(state, articlesLoadedTimestamp) {
			state.articlesLoadedTimestamp = articlesLoadedTimestamp;
		},
		mutateConsentCheckboxText(state, consentCheckboxText) {
			state.consentCheckboxText = consentCheckboxText;
		},
		mutateNotifications(state, notifications) {
			state.notifications = notifications.notificationMessages;
		},
		mutateContracts(state, contracts) {
			state.contracts = contracts.rows
		},
		mutateloadingNotifications(state, loadingNotifications) {
			state.loadingNotifications = loadingNotifications;
		},
		mutateloadingContracts(state, loadingContracts) {
			state.loadingContracts = loadingContracts
		},
		mutateUserLocale(state, userLocale) {
			state.userLocale = userLocale;
		},
		mutateLoadingBenefits(state, loadingBenefits) {
			state.loadingBenefits = loadingBenefits;
		},
		mutateBenefits(state, benefits) {
			state.benefits = benefits;
		},
		mutateBenefitsLoadedLocale(state, benefitsLoadedLocale) {
			state.benefitsLoadedLocale = benefitsLoadedLocale;
		},
		mutateBenefitsLoadedTimestamp(state, benefitsLoadedTimestamp) {
			state.benefitsLoadedTimestamp = benefitsLoadedTimestamp;
		},
		// Used for overwrite settings from DB
		mutateSettings(state, settings) {
			state.settings = settings;
		},
	},
	actions: {
		// Get all settings for current appId from MobileAppSettings. Overwrites defaults in src/WiseSettings.js
		async getMobileAppSettings({ getters, commit, state }) {
			const { data } = await axios.get(`${getters.activeHost.ajaxUrl}?getmobileappsettings=1&appid=${state.settings.appId}&appauth=${getters.activeHost.appauth}`);
			let overWriteSettings = data

			// Convert string true/false to boolean and check if string is number (if true and convert to number)
			for (let [key, value] of Object.entries(overWriteSettings)) {
				if(value === "true" || value === true) {
					value = true;
				} else if (value === "false" || value === false) {
					value = false;
				} else if (!isNaN(value)) {
					value = parseInt(value)
				}
				
				overWriteSettings[key] = value
			}

			// Merge settings
			// QUESTION: Is Deep merge settings needed?
			const mergedSettings = {...state.settings, ...overWriteSettings }
			commit('mutateSettings', mergedSettings)

			// Check if we have notification settings in local storage
			const { value } = await Preferences.get({ key: `notifications-settings-${state.settings.appId}` });

			// If we dont have notification settings in localStorage use default ones
			if(value === null) {
				// console.warn("No notification settings in localStorage use default ones")

				// Overwrite notification settings topics if found topicDefaults in mobileAppSettings
				if(typeof data.topicDefaults !== "undefined") {
					// Get only topics for user locale
					const defaultTopicsByUserLocale = Object.fromEntries(Object.entries(data.topicDefaults).filter(([key]) => key.includes('-'+state.userLocale.split("-")[0])))
					
					commit('mutateDefaultTopics', defaultTopicsByUserLocale)
				}
			} else {
				// We got notification settings from localStorage
				console.log("Found notification settings from localStorage:", value)
				// Add settings to vuex
				commit('mutateNotificationSettings', JSON.parse(value))
			}
		},

		// Get articles category (app / slider_app)
		async getArticles({ getters, commit, state }) {
			try {
				commit('mutateLoadingState', true)
				const sliderArticles = await getSliderArticles(getters, state.userLocale)
				const listArticles = await getListArticles(getters, state.userLocale)
				commit('mutateSliderArticles', sliderArticles.data.items)
				commit('mutateListArticles', listArticles.data.items)
				commit('mutateLoadingState', false)
			} catch (error) {
				commit('mutateLoadingState', false)
				console.error("STORE: getArticles() FAILED")
				console.error(JSON.stringify(error))
				return Promise.reject(error)
			}
		},
		// Load more category app articles (@params object can have page and per_page)
		async loadMoreArticles({ getters, commit, state }, params) {
			try {
				const moreListArticles = await getListArticles(getters, state.userLocale, params.page)
				commit('mutateMoreListArticles', moreListArticles.data.items)
				return moreListArticles.data.items.length
			} catch (error) {
				return Promise.reject(error)
			}
		},

		async getConsentText({ getters, commit, state }) {
			try {
				const { data } = await axios.get(`${getters.activeHost.ajaxUrl}?getconsentcheckbox=1&lang=${state.userLocale}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
				commit('mutateConsentCheckboxText', data)
			} catch (error) {
				return Promise.reject(error)
			}
		},

		async checkSystemNotification({ state }, id) {
			state.checkedSystemNotifications.push(id)

			// preferences get deleted when user logs out and we see the notifications again, not good..
			/*
			await Preferences.set({ 
				key: `checked-system-notifications-${state.settings.appId}`,
				value: JSON.stringify(state.checkedSystemNotifications)
			})
			*/
			localStorage[`checked-system-notifications-${state.settings.appId}`] = JSON.stringify(state.checkedSystemNotifications)
		},

		async loadCheckedSystemNotifications({ state }) {
			/*
			const { value } = await Preferences.get({ key: `checked-system-notifications-${state.settings.appId}` });
			*/
			const value = localStorage[`checked-system-notifications-${state.settings.appId}`]

			if (typeof value !== 'string') return;

			try {
				state.checkedSystemNotifications = JSON.parse(value)
			} catch(e) {
				console.log('failed to read system notifications', value, e)
			}
		},

		async getNotifications({ getters, commit, state }) {
			commit('mutateloadingNotifications', true)

			const notificationsPostData = { topics: Object.keys(state.notificationsSettings.topics), appId: state.settings.appId }

			try {
				const { data } = await axios.post(`${getters.activeHost.ajaxUrl}?getnotificationmessages=1&lang=${state.userLocale}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`, notificationsPostData)
				if (data.notificationMessages) {
					commit('mutateNotifications', data)
				}
				commit('mutateloadingNotifications', false)
			} catch (error) {
				commit('mutateloadingNotifications', false)
				return Promise.reject(error)
			}
		},

		async getBenefits({ getters, commit, state }) {
			commit('mutateLoadingBenefits', true)
			try {
				// Get benefits categories
				const benefitsCategoriesData = await axios.get(`${getters.activeHost.restUrl}categories/?producttype=7&categorytype=4&lang=${state.userLocale}`)
				const benefitsCategories = benefitsCategoriesData.data.rows

				// Get benfits by categoryId
				for (const [index, category] of benefitsCategories.entries()) {
					const categoryBenefitsData = await axios.get(`${getters.activeHost.restUrl}products/?categories.categoryid=${category.categoryId}&select=productid,meta,type,tickettype,price,benefitprice,trackinventory,maximum,visibility,active,manufacturer,dateofpublication,expirationdate,images&type=7&lang=${state.userLocale}`)
					benefitsCategories[index].benefits = categoryBenefitsData.data.rows
				}

				if (benefitsCategories.length > 0) {
					commit('mutateBenefits', benefitsCategories)
					commit('mutateBenefitsLoadedTimestamp', Math.floor(Date.now() / 1000))
					commit('mutateBenefitsLoadedLocale', state.userLocale)
				}
				commit('mutateLoadingBenefits', false)
			} catch (error) {
				console.error("getBenefits()", error)
				commit('mutateLoadingBenefits', false)
				commit('mutateBenefitsLoadedTimestamp', null)
				commit('mutateBenefitsLoadedLocale', null)
				return Promise.reject(error)
			}
		},

		async getContracts({getters, commit}) {
			commit('mutateloadingContracts', true)
			try {
				const { data } = await axios.get(`${getters.activeHost.restUrl}contracts/`)
				console.log(data.rows);

				if (data.rows) {
					commit('mutateContracts', data)
				}
				commit('mutateloadingContracts', false)
			} catch (error) {
				console.error("getContracts() error: ", error)
				commit('mutateloadingContracts', false)
			}
		},

		async getBenefitAvailability({ getters, state }, benefitId) {
			try {
				const { data } = await axios.get(`${getters.activeHost.restUrl}/benefit/${benefitId}/availability?lang=${state.userLocale}`)
				return data.rows
			} catch (error) {
				console.error("getBenefitAvailability()", error)
				return Promise.reject(error)
			}
		},

		async postUseBenefit({ getters }, benefitId) {
			try {
				const { data } = await axios.post(`${getters.activeHost.restUrl}/benefit/${benefitId}/use`)
				console.log("useBenefit", data)
				return data
			} catch (error) {
				console.error("useBenefit()", error)
				return Promise.reject(error)
			}
		}
	}
}

const getListArticles = async (getters, locale = 'fi-fi', page = 1, perPage = 10) => {
	try {
		const { activeHost } = getters
		const { articleCategory } = activeHost
		const category = articleCategory || 'app'
		return await axios.get(`${getters.activeHost.ajaxUrl}?controller=ajax&appmode=1&method=getLastItems2&lang=${locale}&categoryName=${ category }&page=${page}&per_page=${perPage}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
	} catch (error) {
		console.error(error)
	}
}
const getSliderArticles = async (getters, locale = 'fi-fi', page = 1, perPage = 10) => {
	try {
		return await axios.get(`${getters.activeHost.ajaxUrl}?controller=ajax&appmode=1&method=getLastItems2&lang=${locale}&categoryName=app_slider&page=${page}&per_page=${perPage}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
	} catch (error) {
		console.error(error)
	}
}