/* eslint-disable no-unused-vars */
import moment from 'moment';
import { wnmoment } from '@/WiseEcom/services/WiseDate'
import { formatDateTime, dateFormat } from '@/WiseEcom/services/WiseDates.js'
import { _locale } from '@/WiseEcom/services/WiseLocale';

const format = 'YYYY-MM-DD HH:mm';
const formatTime = { second: 0, millisecond: 0 };

export const TIMETABLE_TYPE = {
    weekCalendar: 0,
    resource: 1,
    golf: 4,
    equipment: 5,
};
export const TIMEBLOCK_TYPE = {
    free: 0,
    reservation: 1,
    disabled: 2,
    startTooClose: 3,
    endTooClose: 4,
    reservationStillRoom: 5, // for when resource has available quantity > 1
    reservationFull: 6,
    reservationInCart: 7,
    disallowedWithinTime: 8,
    disabledByRule: 9,
    startDisabled: 10, // starting a reservation is disabled
    resellableReservation: 11,
    websocketReservation: 99,
};

export const CALENDAR_TYPE = {
    weekCalendar: 1,
    grid: 2, // like resource but different rendering
    resource: 3, // padel kenttä 1, kenttä 2, kenttä 3
    resourceEquipment: 4, // padel stick, bicycle, maybe a person(?)...
    golf: 5
}

export function serviceFactory({ product, settings, selectedDate, reservations, admin, resourceRules }) {
    const {
        startTime, endTime, duration,
        breakTime, leadTime, minDuration,
        maxDuration, recurrenceDays,
        hideEndTime, 
        minDurationBetweenReservations, 
        disallowReservationsWithin,
    } = settings;
    
    let serviceSettings = {
        price: product.price,
        reservationId: settings.reservationId,
        duration,
        breakTime,
        leadTime,
        minDuration,
        maxDuration,
        baseDay: selectedDate,
        reservations,
        resources: settings.resources || [],
        hideEndTime, 
        minDurationBetweenReservations, 
        disallowReservationsWithin,
        admin,
        //...settings.settings,
        resourceRules
    };
    
    if (settings.type === CALENDAR_TYPE.weekCalendar) {
        serviceSettings = {
            ...serviceSettings,
            type: TIMETABLE_TYPE.weekCalendar,
            calendarType: CALENDAR_TYPE.weekCalendar,
            startTime,
            endTime,
            recurrenceDays
        };
    } else if (settings.type === CALENDAR_TYPE.resource 
        || settings.type === CALENDAR_TYPE.grid) {
        serviceSettings = {
            ...serviceSettings,
            type: TIMETABLE_TYPE.resource,
            calendarType: settings.type,
            startTime: `${ selectedDate.format('YYYY-MM-DD') } ${ startTime }`,
            endTime: `${ selectedDate.format('YYYY-MM-DD') } ${ endTime }`
        };
    } else if (settings.type === CALENDAR_TYPE.golf) {
        serviceSettings = {
            ...serviceSettings,
            type: TIMETABLE_TYPE.golf,
            calendarType: settings.type,
            startTime: `${ selectedDate.format('YYYY-MM-DD') } ${ startTime }`,
            endTime: `${ selectedDate.format('YYYY-MM-DD') } ${ endTime }`
        };
    } else if (settings.type === CALENDAR_TYPE.resourceEquipment) {
        serviceSettings = {
            ...serviceSettings,
            type: TIMETABLE_TYPE.equipment,
            calendarType: settings.type,
            startTime: `${ selectedDate.format('YYYY-MM-DD') } ${ startTime }`,
            endTime: `${ selectedDate.format('YYYY-MM-DD') } ${ endTime }`
        };
    }
    
    return new ReservationService(serviceSettings)
}

export function testRuleToBlock(rule, block, debug = false) {
    const blockStart = block.start.format('x');
    const blockEnd = block.end.format('x');

    if (Array.isArray(rule.recurrenceDays)) {
        const weekCopy = [...rule.recurrenceDays];
        const sunday = weekCopy.pop();

        weekCopy.unshift(sunday);
        
        if (!weekCopy[block.start.day()]) {
            return debug ? { weekCopy, day: block.start.day() } : false;
        }
    }
    
    if (rule.dateStart && rule.dateEnd) {
        const start = wnmoment(`${ rule.dateStart } ${ rule.startTime ? rule.startTime : '00:00:00' }`).set(formatTime);
        const end = wnmoment(`${ rule.dateEnd } ${ rule.endTime ? rule.endTime : '23:59:59' }`).set(formatTime);
        const collision = timeBlocksCollide(
            -(-start.format('x')), 
            -(-end.format('x')),
            -(-blockStart),
            -(-blockEnd),
            true
        );

        if (!collision.fitsInside) {
            return debug ? collision : false;
        }
    }
    
    if (typeof rule.startTime === 'string'
        && typeof rule.endTime === 'string') {
        const parts = ["hour", "minute", "second"];
        const timeReduce = (acc,curr, i) => {
            acc[parts[i]] = -(-curr);
            return acc;
        };
        const startTimeObj = rule.startTime.split(':').reduce(timeReduce,{millisecond:0});
        const endTimeObj = rule.endTime.split(':').reduce(timeReduce,{millisecond:0});
        const start = block.start.clone().set(startTimeObj);
        const end = block.start.clone().set(endTimeObj)

        if (end < start) {
            end.add(24, 'hour')
        }
            
        const collision = timeBlocksCollide(
            -(-start.format('x')), 
            -(-end.format('x')),
            -(-blockStart),
            -(-blockEnd),
            true
        );
        
        if (!collision.fitsInside) {
            return debug ? collision : false;
        }
    }
    
    return true;
}

export function validateResourceRule(rule, date) {
    if (Array.isArray(rule.recurrenceDays)) {
        const weekCopy = [...rule.recurrenceDays];
        const sunday = weekCopy.pop();

        weekCopy.unshift(sunday);
        
        if (!weekCopy[date.day()]) {
            return false;
        }
    }
    
    if (typeof rule.startDate === 'string') {
        const settingsStart = moment(`${ rule.startDate }`);
        
        if (settingsStart > date) {
            return false;
        }
    }
    
    if (typeof rule.endDate === 'string') {
        const settingsEnd = moment(`${ rule.endDate }`);
        
        if (settingsEnd < date) {
            return false;
        }
    }

    // now the rule is valid and can be used
    return true;
}

/**
* Datetimes in unix timestamps only!
* 
* @param {Number} start
* @param {Number} end
* @param {Number} testStart
* @param {Number} testEnd
* @param {Boolean} debug
* @returns {Boolean}
*/
export function timeBlocksCollide(start, end, testStart, testEnd, debug = false) {
   const fitsInside = testStart >= start && testEnd <= end;
   const tailInside = testStart < start && testEnd > start && testEnd <= end;
   const headInside = testStart >= start && testStart < end && testEnd > end;
   const overlaps = testStart < start && testEnd > end;
   
   if (debug) {
       return {
           overlaps,
           fitsInside,
           tailInside,
           headInside
       };
   }

   return overlaps || fitsInside || headInside || tailInside;
}

export function getDuration(start, end, absoluteValue = true) {
    const diff = wnmoment(start).diff(wnmoment(end), 'minutes');

    if (absoluteValue) {
        // abs to ensure a positive value
        return Math.abs(diff)
    }
    
    return diff;
}

export function durationToBlockCount(start, end, blockLength) {
    // ceil to ensure an integer value
    // abs to ensure a positive value
    return Math.ceil(
        getDuration(start, end) / blockLength
    );
}

export function getBlockIndex(dateTime, calendarStart, blockLength) {
    const blockCount = durationToBlockCount(calendarStart, dateTime, blockLength)

    return blockCount;
}

/**
 * Timeformat required: 'YYYY-MM-DD HH:mm'
 * 
 * @param {String} dateTime
 * @returns {undefined}
 */
export function toTimestamp(dateTime) {
    return -(-moment(dateTime, format).set(formatTime).format('X'))
}

export class ReservationService {
    constructor(settings) {
        this.settings = settings;
        this.data = [];
        // this is run manually from store
        //this.init();
    }
    
    init() {
        const start = Date.now();
        // define calendar step in minutes
        const duration = this.settings.duration;
        let step = 30;
        
        if (duration % 30 === 0) {
            step = 30;
        } else if (duration % 15 === 0) {
            step = 15;
        } else if (duration % 10 === 0) {
            step = 10;
        }
        
        this.step = step;
        
        switch(this.settings.type) {
            case TIMETABLE_TYPE.weekCalendar:
                this.data = this.createWeekTimeTable(this.settings.baseDay);
            break;
            case TIMETABLE_TYPE.golf:
            case TIMETABLE_TYPE.resource:
                this.data = this.createResourceTimeTable();
            break;
            case TIMETABLE_TYPE.equipment:
                this.data = this.createEquipmentTimeTable();
                this.step = 60;
            break;
        }

        console.log('init', Date.now() - start) // (new Error()).stack
    }
            
    createResourceTimeTable() {
        const settings = this.settings;
        const table = settings.resources.map((resource, i) => {
            let start = moment(settings.startTime);
            let end = moment(settings.endTime);
            let duration = settings.duration;
            let leadTime = settings.leadTime;
            let breakTime = settings.breakTime;

            if (Array.isArray(settings.resourceRules) 
                    && settings.resourceRules.length > 0) {
                const rules = settings.resourceRules
                    .filter(row => row.resourceId === resource.resourceId || row.resourceId === null)
                    .filter(row => validateResourceRule(row, start));
                const generalResourceExceptions = rules.filter(row => row.ruleName === 'resurssiPoikkeus' && !row.isUserRule)
                const userResourceExceptions = rules.filter(row => row.ruleName === 'resurssiPoikkeus' && row.isUserRule)
                const resourceExceptions = userResourceExceptions.length > 0 ? userResourceExceptions : generalResourceExceptions;

                if (resourceExceptions.length > 0) {
                    const exceptionRule = resourceExceptions[0];
                    const ruleValue = exceptionRule.ruleValue || {};

                    if (exceptionRule.startTime) {
                        start = moment(`${ start.format('YYYY-MM-DD') } ${ exceptionRule.startTime }`);
                    }

                    if (exceptionRule.endTime) {
                        end = moment(`${ end.format('YYYY-MM-DD') } ${ exceptionRule.endTime }`);
                    }
                    
                    if (ruleValue.duration) {
                        duration = ruleValue.duration;
                    }
                }
            }
            
            if (end <= start) {
                end.add(24, 'hours')
            }

            const timeBlocks = this.generateDayTimes(
                start.format('YYYY-MM-DD HH:mm:ss'),
                end.format('YYYY-MM-DD HH:mm:ss'),
                duration,
                leadTime,
                breakTime,
                resource
            );
            
            return {
                id: i + 1,
                title: resource.resourceName,
                resourceId: resource.resourceId,
                resourceName: resource.resourceName,
                date: settings.startTime,
                timeBlocks
            };
        });
        
        return table;
    }

    createEquipmentTimeTable() {
        const settings = this.settings;
        const { resources, reservations, startTime } = settings;
        const table = resources.map((resource, i) => {
            /*
            let start = moment(startTime);
            let end = moment(endTime);
            
            if (end <= start) {
                end.add(24, 'hours')
            }
            */
            
            const resourceReservationsFilter = (reservation) => reservation.resources.filter(
                r => r.resourceId === resource.resourceId
            ).length > 0;
            
            return {
                id: i + 1,
                title: resource.resourceName,
                resourceId: resource.resourceId,
                resourceName: resource.resourceName,
                date: startTime,
                reservations: reservations
                    .filter(resourceReservationsFilter)
            };
        });
        
        return table;
    }
    
    createWeekTimeTable(baseDay) {
        const settings = this.settings;
        const enabledDays = settings.recurrenceDays;
        const table = settings.resources.map((resource, i) => {
            const weekStart = moment(baseDay).startOf('isoWeek').set(formatTime);
            //const weekEnd = moment(baseDay).endOf('isoWeek').set(formatTime);
            const days = [];
        
            for(let i=0;i < 7;i++) {
                const dayObj = {
                    id: weekStart.clone().day(),
                    title: `${ formatDateTime(dateFormat.DATETIME_DAY_NAME_SHORT, weekStart) } ${ formatDateTime(dateFormat.DATETIME_DM, weekStart) }`,
                    shortTitle: `${ formatDateTime(dateFormat.DATETIME_DAY_NAME_SHORT, weekStart) }`,
                    date: weekStart.clone(),
                    resourceId: resource.resourceId,
                    resourceName: resource.resourceName,
                    timeBlocks: []
                };

                if (enabledDays[i]) {
                    let start = moment(
                        weekStart.format('YYYY-MM-DD ' + settings.startTime)
                    );
                    let end = moment(
                        weekStart.format('YYYY-MM-DD ' + settings.endTime)
                    );
                    let duration = settings.duration;
                    let leadTime = settings.leadTime;
                    let breakTime = settings.breakTime;

                    if (Array.isArray(settings.resourceRules) 
                            && settings.resourceRules.length > 0) {
                        const rules = settings.resourceRules
                            .filter(row => row.resourceId === resource.resourceId || row.resourceId === null)
                            .filter(row => validateResourceRule(row, start));
                        const generalResourceExceptions = rules.filter(row => row.ruleName === 'resurssiPoikkeus' && !row.isUserRule)
                        const userResourceExceptions = rules.filter(row => row.ruleName === 'resurssiPoikkeus' && row.isUserRule)
                        const resourceExceptions = userResourceExceptions.length > 0 ? userResourceExceptions : generalResourceExceptions;

                        if (resourceExceptions.length > 0) {
                            const exceptionRule = resourceExceptions[0];
                            const ruleValue = exceptionRule.ruleValue || {};

                            if (exceptionRule.startTime) {
                                start = moment(`${ start.format('YYYY-MM-DD') } ${ exceptionRule.startTime }`);
                            }

                            if (exceptionRule.endTime) {
                                end = moment(`${ end.format('YYYY-MM-DD') } ${ exceptionRule.endTime }`);
                            }
                            
                            if (ruleValue.duration) {
                                duration = ruleValue.duration;
                            }
                        }
                    }
                    
                    if (end <= start) {
                        end.add(24, 'hours')
                    }
                    
                    dayObj.timeBlocks = this.generateDayTimes(
                        start.format('YYYY-MM-DD HH:mm:ss'),
                        end.format('YYYY-MM-DD HH:mm:ss'),
                        duration,
                        leadTime,
                        breakTime,
                        resource
                    );
                }

                days.push(dayObj);

                weekStart.add(1, 'days')
            }
            
            return {
                id: i + 1,
                title: resource.resourceName,
                resourceId: resource.resourceId,
                days
            };
        })
        
        return table;
    }
    
    getBlockTotalLength() {
        const { duration, breakTime } = this.settings;
        
        return duration + (breakTime || 0);
    }
    
    /**
     * Timeformat required: 'YYYY-MM-DD HH:mm'
     * 
     * @param {String} startTime
     * @param {String} endTime
     * @param {Number} duration minute amount
     * @param {Number} leadTime minute amount "cooldown" before the reservation
     * @param {Number} breakTime minute amount "cooldown" after the reservation
     * @param {Object} resource
     * @returns {Array}
     */
    generateDayTimes(startTime, endTime, duration, leadTime = 0, breakTime = 0, resource = {}) {
        const calendarType = this.settings.calendarType;
        const totalLength = duration + breakTime;
        const minDuration = this.settings.minDuration; // for actual reservation, not for a block
        const minDurationBetweenReservations = this.settings.minDurationBetweenReservations;
        const start = moment(startTime, format).set(formatTime);
        const end = moment(endTime, format).set(formatTime);
        const delta = start.clone();
        const now = wnmoment().set(formatTime).add(totalLength, 'minutes');
        const blockCount = durationToBlockCount(start, end, totalLength);
        const resourceReservationsFilter = (reservation) => reservation.resources.filter(r => r.resourceId === resource.resourceId).length > 0;
        const reservations = this.settings.reservations.filter(resourceReservationsFilter);
        const timeBlocks = [];
        const handleMultipleSlots = resource.quantity > 1 || this.settings.calendarType === CALENDAR_TYPE.golf;
        let rules = [];
        let disallow = null;
        let usedReservationIds = [];
        let lastReservation = null;

        if (Array.isArray(this.settings.resourceRules) 
            && this.settings.resourceRules.length > 0) {
            rules = this.settings.resourceRules
                .filter(row => row.resourceId === resource.resourceId || row.resourceId === null)
        }
        
        const timeBlockReservations = (block) => {
            const found = reservations
            .filter(row => !usedReservationIds.includes(row.reservationTimeId))
            .filter(row => Math.abs(wnmoment(row.start).diff(block.start.toDate(), 'minutes')) < totalLength * 2)
            .filter(reservation => timeBlocksCollide(
                wnmoment(reservation.start).format('X'),
                wnmoment(reservation.end).format('X'),
                block.start.format('X'),
                block.end.format('X')
            ))
            usedReservationIds = [
                ...usedReservationIds,
                ...found.map(row => row.reservationTimeId)
            ]

            return found;
        }
        
        const isGolf = calendarType === CALENDAR_TYPE.golf;
        const getBlockReservations = isGolf && totalLength < 30 && resource.quantity > 2
            ? timeBlockReservations 
            : this.timeBlockReservations;
        
        if(this.settings.disallowReservationsWithin) {
            disallow = moment();
            disallow.add(this.settings.disallowReservationsWithin, 'hours');
        }

        if (rules.length > 0) {
            const [ resourceOverride ] = rules.filter(row => row.ruleName === 'resurssiYliajo')

            if (resourceOverride) {
                const ruleValue = resourceOverride.ruleValue || {}

                if (ruleValue.startOffset) {
                    delta.add(ruleValue.startOffset, 'minute')
                }
            }
        }
        
        // this code is kind of a mess due to features added by time..
        for(let i = 0;i < blockCount;i++) {
            const fromStart = getDuration(delta, start.startOf('hours'));
            const blockEnd = delta.clone().add(duration, 'minutes');
            
            let type = TIMEBLOCK_TYPE.free;
            let title = !isGolf
                ? formatDateTime(dateFormat.DATETIME_HI, delta)
                : '';
            let past = now.format('x') >= blockEnd.format('x');
            
            /*
            if (now.format('x') >= blockEnd.format('x')) {
                type = TIMEBLOCK_TYPE.disabled;
            }
            */
           
            if (!past) {
                if (lastReservation !== null) {
                    const diffMinutes = getDuration(delta, lastReservation);
                    const diffToNow = getDuration(delta, wnmoment());
                    //if (diffToNow < totalLength) console.log('diffToNow',diffToNow, delta.format('HH:mm'));
                    if (diffToNow > totalLength
                            && diffMinutes > 0 
                            && minDurationBetweenReservations > 0 
                            && diffMinutes <= minDurationBetweenReservations) {
                        type = TIMEBLOCK_TYPE.startTooClose;
                    }
                }

                const nextReservation = reservations.filter(
                    (reservation) => { 
                        const diffMinutes = getDuration(reservation.start, delta, false);
                        
                        return diffMinutes > 0 && diffMinutes + totalLength < minDuration;
                    }
                );

                if (nextReservation.length > 0) {
                    if (!handleMultipleSlots) {
                        type = TIMEBLOCK_TYPE.endTooClose;
                    }
                }

                if (getDuration(delta, end) < minDuration) {
                    type = TIMEBLOCK_TYPE.endTooClose;
                }
            }
            
            if(this.settings.disallowReservationsWithin && !this.settings.admin) {
                if(delta.isBefore(disallow)) {
                    type = TIMEBLOCK_TYPE.disallowedWithinTime;
                }
            }
            
            
            if (duration >= 60 && !this.settings.hideEndTime) {
                title += ' - ' + formatDateTime(dateFormat.DATETIME_HI, blockEnd);
            }
            
            const block = {
                blockId: `${ resource.resourceId }-${ i }`,
                blockKey: `block-${ i }`, // for vue element :key=""
                type,
                title: title,
                start: delta.clone(),
                end: blockEnd.clone(),
                top: (fromStart / duration),
                height: Math.floor((duration / this.step)),
                priceOverride: false,
                duration,
                leadTime,
                breakTime,
                resource,
                info: '',
                past,
                availableQuantity: resource.quantity,
                reservations: [], // by default empty
                hide: false,
            };
            
            if (type !== TIMEBLOCK_TYPE.disabled) {
                // check if the time block is reserved
                //const timing = Date.now();
                const found = getBlockReservations(block, reservations);
                //console.log('found', Date.now() - timing)
                if (found.length > 0) {
                    block.reservations = found;
                    
                    if (handleMultipleSlots) {
                        //const reservatedQuantity = found.reduce((acc, curr) => acc + curr.quantity, 0)
                        // check if sum of reservation quantities is less than available quantity
                        // note that reservation quantity when creating an order is block array length * orderQuantity
                        // resource.quantity === available quantity for reservation
                        let quantity = 0;
                        let disabled = false;
                        found.forEach((row) => {
                            const reservatedResource = row.resources.filter(_resource => _resource.resourceId === resource.resourceId)[0] || {quantity:0};
                            
                            quantity += reservatedResource.quantity;
                            
                            if (row.status === 4) {
                                // if one of the reservations is actually a disabled time, it takes over
                                // NOPE, just effect the quantity
                                //disabled = true;
                            } else {
                                // mainly just that the block can indicate a problem in some payment
                                if (row.orderId && row.orderStatusId != 2) {
                                    const totalPaid = -(-row.totalPaid); // number parse hack

                                    if (totalPaid > 0) {
                                        block.partlyPaid = true;
                                    } else {
                                        block.paymentDelayed = true;
                                    }
                                }
                            }
                        })
                        block.availableQuantity = resource.quantity - quantity;
                        block.type = isGolf || block.availableQuantity > 0 ? TIMEBLOCK_TYPE.reservationStillRoom : TIMEBLOCK_TYPE.reservationFull;
                        // availableQuantity should not go below zero but sometimes shit happen
                        if (block.availableQuantity < 0) {
                            console.log('WARNING! block.availableQuantity < 0', block);
                            block.availableQuantity = 0;
                        }

                        if (disabled) {
                            block.type = TIMEBLOCK_TYPE.reservation;
                            lastReservation = block.end;
                        
                            if (this.settings.admin) {
                                block.type = TIMEBLOCK_TYPE.disabled;
                                block.info = `${ _locale('time disabled').ucfirst() }: 
                                    ${ found[0].lastName } ${ found[0].firstName }`; 
                                block.disabled = true;
                                block.disableReason = `${ found[0].extraInformation ? found[0].extraInformation : '' }`;
                            }
                        }
                        //console.log('block', block)
                    } else {
                        // separate blocks where there can be only one reservations vs multiple reservations per block
                        block.type = TIMEBLOCK_TYPE.reservation;
                        lastReservation = block.end;

                        if (this.settings.admin) {
                            const status = found[0].isCart ? _locale('in cart').ucfirst() : _locale('reserved').ucfirst();

                            if (found[0].status === 4) {
                                block.type = TIMEBLOCK_TYPE.disabled;
                                block.info = `${ _locale('time disabled').ucfirst() }: 
                                ${ found[0].lastName } ${ found[0].firstName }`; 
                                block.disabled = true;
                                block.disableReason = `${ found[0].extraInformation ? found[0].extraInformation : '' }`;
                            } else {
                                if (found[0].orderId && found[0].orderStatusId != 2) {
                                    const totalPaid = -(-found[0].totalPaid); // number parse hack
                                    
                                    if (totalPaid > 0) {
                                        block.partlyPaid = true;
                                    } else {
                                        block.paymentDelayed = true;
                                    }
                                }

                                if (!found[0].isCart) {
                                    let nameAbbr = found[0].firstName && found[0].lastName ? ` - ${ found[0].lastName } ${ found[0].firstName[0] }.` : '';

                                    if (typeof found[0].company === 'string' && found[0].company.length > 0) {
                                        nameAbbr = ` - ${ found[0].company }`;
                                    }

                                    block.title = `${ block.title }${ nameAbbr }`;
                                } else {
                                    block.type = TIMEBLOCK_TYPE.reservationInCart;
                                }

                                let reservedName = `${ found[0].lastName } ${ found[0].firstName }`;

                                if (typeof found[0].company === 'string' && found[0].company.length > 0) {
                                    reservedName = `${ found[0].company } (${ reservedName })`
                                }

                                block.info = `${ status }: ${ reservedName }`;
                                
                                //console.log('found[0]', found[0], block);
                            }
                        }

                        // resellable reservation (connected to shares)
                        if (found[0].status === 6) {
                            block.type = TIMEBLOCK_TYPE.resellableReservation;
                        } else if (found[0].isSellable) {
                            block.isSellable = true;
                        }

                        if (found[0].shareId) {
                            block.isShareReservation = true;
                        }

                        if (found[0].label === 'res_recurring') {
                            block.isRecurring = true;
                        }

                        if (found[0].isUserReservation) {
                            block.isUserReservation = true;
                        }

                        if (found[0].isSold) {
                            block.isSold = true;
                        }
                    }
                }
                
                if (Array.isArray(resource.priceRules)) {
                    const priceSettings = resource.priceRules
                        .filter(row => validateResourceRule(row, block.start))
                        .filter(row => testRuleToBlock(row, block))[0];
                    
                    if (priceSettings) {
                        block.priceOverride = -(-priceSettings.newPrice);
                    }
                }
                
                if (rules.length > 0) {
                    const userBlockOptionsRules = rules.filter(row => row.ruleName === 'lohkoAsetukset' && row.categoryId && row.isUserRule)
                        .filter(row => validateResourceRule(row, block.start))
                        .filter(row => testRuleToBlock(row, block));
                    const commonBlockOptionsRules = rules.filter(row => row.ruleName === 'lohkoAsetukset' && !row.categoryId)
                        .filter(row => validateResourceRule(row, block.start))
                        .filter(row => testRuleToBlock(row, block));
                    const blockOptionsRules = userBlockOptionsRules.length > 0 ? userBlockOptionsRules : commonBlockOptionsRules;
                        
                    if (blockOptionsRules.length > 0 
                        && block.type === TIMEBLOCK_TYPE.free) {
                        const ruleValue = blockOptionsRules[0].ruleValue || {};
                        const blockMinutes = parseInt(block.start.format('m'));
                        
                        if (typeof ruleValue.minuteModulus !== 'undefined') {
                            if (blockMinutes != ruleValue.minuteModulus) {
                                block.type = TIMEBLOCK_TYPE.startDisabled;

                                if (ruleValue.minuteModulus === 0) {
                                    block.info = `${ _locale('starting reservations enabled only on even hours').ucfirst() }`;
                                } else if (ruleValue.minuteModulus === 30) {
                                    block.info = `${ _locale('starting reservations enabled only on the half-hour').ucfirst() }`;
                                } else {
                                    block.info = `${ _locale('starting reservations enabled only every %s minutes', ruleValue.minuteModulus).ucfirst() }`;
                                }
                            } else {
                                block.isStartingPoint = true;
                            }
                        }
                    }

                    const userDisableTimeRules = rules.filter(row => row.ruleName === 'aikaSulku' && row.categoryId && row.isUserRule);
                    const commonDisableTimeRules = rules.filter(row => row.ruleName === 'aikaSulku' && !row.categoryId);
                    const disableTimeRules = (userDisableTimeRules.length > 0 ? userDisableTimeRules : commonDisableTimeRules)
                        .filter(row => validateResourceRule(row, block.start)) // this doesn't have bugs with start&end dates check :D
                        .filter(row => testRuleToBlock(row, block)); // some bugs maybe with the start&end date check?;
                        
                    if (disableTimeRules.length > 0) {
                        const ruleValue = disableTimeRules[0].ruleValue;

                        if ((this.settings.admin && ruleValue.admin) || !this.settings.admin) {
                            block.availableQuantity = 0;
                            block.type = TIMEBLOCK_TYPE.disabledByRule;
                            block.info = `${ _locale('time disabled').ucfirst() }`

                            if (ruleValue.comment) {
                                block.disableComment = ruleValue.comment
                            }
                            
                            if (typeof ruleValue === 'object' && ruleValue !== null) {
                                if (ruleValue.hide) {
                                    block.hide = true;
                                }
                            }
                        }
                    }
                    
                    // golf membership prices are looked up per player and this is per block & user
                    if (isGolf === false) {
                        const membershipPrices = rules.filter(row => row.ruleName === 'pelioikeus' && row.isUserRule)
                            .filter(row => validateResourceRule(row, block.start))
                            .filter(row => testRuleToBlock(row, block));
                        
                        if (!this.settings.admin && membershipPrices.length > 0) {
                            const basePrice = block.priceOverride !== false ? block.priceOverride : this.settings.price;
                            const newAPrice = membershipPrices.reduce((acc, curr) => {
                                if (typeof curr.ruleValue !== 'object') return acc;

                                const { price: newPrice, percent: newPercent } = curr.ruleValue;
                                let price = acc;

                                if (typeof newPrice === 'number') {
                                    price = acc === null || newPrice < acc ? newPrice : acc;
                                }

                                if (typeof newPercent === 'number') {
                                    if (price === null) {
                                        price = basePrice;
                                    }

                                    const percent = newPercent / 100;
                                    price = price - (price * percent);
                                }

                                return price;
                            }, null)
    
                            if (newAPrice !== null) {
                                block.priceOverride = newAPrice;
                            }
                        }                        
                    }
                }
            }
            
            // let's check if the type needs changing
            // this should actually know about the next block's reservations
            /*
            if (handleMultipleSlots 
                && block.availableQuantity > 0
                && blockEnd.format('x') !== end.format('x')) {
                if ([TIMEBLOCK_TYPE.endTooClose,TIMEBLOCK_TYPE.startTooClose].indexOf(type) > -1) {
                    block.type = TIMEBLOCK_TYPE.free;
                }
            }
            */
            
            switch(block.type) {
                case TIMEBLOCK_TYPE.startTooClose:
                    block.info = `${_locale('there can\'t be %s minutes between reservations', minDurationBetweenReservations).ucfirst()}`;
                break;
                case TIMEBLOCK_TYPE.endTooClose:
                    block.info = `${ _locale('minimal time for a reservation is').ucfirst() } ${ minDuration } ${ _locale('Minutes').toLowerCase() }.`;
                    //${ _locale('next reservation starts at').ucfirst() }: ${ moment(nextReservation[0].start).format('HH:mm') }
                break;
                case TIMEBLOCK_TYPE.free:
                    if (minDuration) {
                        const minEnd = delta.clone().add(minDuration, 'minutes');
                        block.info = `${ _locale('free for reservation').ucfirst() } ${ _locale('at least') } ${ formatDateTime(dateFormat.DATETIME_HI, block.start) } - ${ formatDateTime(dateFormat.DATETIME_HI, minEnd) }`;
                    } else {
                        block.info = `${ _locale('free for reservation').ucfirst() } ${ formatDateTime(dateFormat.DATETIME_DM, block.start) } - ${ formatDateTime(dateFormat.DATETIME_HI, block.end) }`;
                    }
                break;
                case TIMEBLOCK_TYPE.reservationStillRoom:
                    
                break;
                case TIMEBLOCK_TYPE.reservationFull:
                    
                break;
                case TIMEBLOCK_TYPE.disallowedWithinTime:
                    block.hide = true;
                break;
            }
            
            if (!this.settings.admin 
                    && past 
                    && block.type !== TIMEBLOCK_TYPE.reservation) {
                block.info = _locale('impossible to make a reservation in the past').ucfirst();
            }
            
            timeBlocks.push(block);
            delta.add(totalLength, 'minutes');
            //if (resource.resourceId) console.log({duration, totalLength, breakTime})
        }

        return timeBlocks;
    }
    
    /**
     * Generates base for hourly system
     * 
     * @param {type} day
     * @param {type} startTime
     * @param {type} endTime
     * @returns {ReservationService.generateDayTimes.timeBlocks|Array}
     */
    createDayTimeBlocks(day, startTime, endTime) {
        const start = moment(`${ day.format('YYYY-MM-DD') } ${ startTime }`).startOf('hours').set(formatTime);
        const end = moment(`${ day.format('YYYY-MM-DD') } ${ endTime }`); // .set(formatTime) no-no ...?
        const step = this.settings.type === TIMETABLE_TYPE.equipment ? this.step : this.step * 2;
        
        if (end <= start) {
            end.add(24, 'hours')
        } else {
            const offsetRule = this.settings.resourceRules.filter(row => row.ruleName === 'resurssiYliajo')

            if (offsetRule.length > 0) {
                // find the biggest start offset available
                // and add it to the end to grow the timetable accordingly
                const length = offsetRule.reduce(
                    (acc, curr) => {
                        const { startOffset } = curr.ruleValue
                        
                        if (isFinite(startOffset) && startOffset > acc) {
                            return startOffset
                        }

                        return acc
                    },
                    0
                )

                end.add(length, 'minute')
            }
        }
        
        return this.generateDayTimes(
            start, 
            -(-end.format('m')) > 0 ? end.endOf('hours').add(1, 'seconds') : end, 
            step
        )
    }
    
    /**
     * 
     * @param {Object} block
     * @param {Array} reservations
     * @returns {Array}
     */
    timeBlockReservations(block, reservations) {
        return reservations.filter(reservation => timeBlocksCollide(
            wnmoment(reservation.start).format('X'),
            wnmoment(reservation.end).format('X'),
            block.start.format('X'),
            block.end.format('X')
        ));
    }

    getPriceSettings(resource, block, dayStart) {
        const blockStart = block.start.format('x');
        const blockEnd = block.end.format('x');
        const weekCopy = [...resource.recurrenceDays];
        const sunday = weekCopy.pop();

        weekCopy.unshift(sunday);
        
        if (!weekCopy[block.start.day()]) {
            return false;
        }
        
        if (resource.dateStart && resource.dateEnd) {
            const start = wnmoment(resource.dateStart+' '+resource.startTime).set(formatTime);
            const end = wnmoment(resource.dateEnd+' '+resource.endTime).set(formatTime);
            const collision = timeBlocksCollide(
                -(-start.format('x')), 
                -(-end.format('x')),
                -(-blockStart),
                -(-blockEnd),
                true
            );
    
            if (!collision.fitsInside) {
                return false;
            }
        } else if (resource.dateStart) {
            const start = wnmoment(resource.dateStart+' '+resource.startTime).set(formatTime);

            if (block.start < start) {
                return false;
            }
        } else if (resource.dateEnd) {
            const end = wnmoment(resource.dateEnd+' '+resource.endTime).set(formatTime);

            if (block.end > end) {
                return false;
            }
        }
        
        const parts = ["hour", "minute", "second"];
        const timeReduce = (acc,curr, i) => {
            acc[parts[i]] = -(-curr);
            return acc;
        };
        const startTimeObj = resource.startTime.split(':').reduce(timeReduce,{millisecond:0});
        const endTimeObj = resource.endTime.split(':').reduce(timeReduce,{millisecond:0});
        const start = wnmoment(dayStart).set(startTimeObj);
        const end = wnmoment(dayStart).set(endTimeObj);
        const collision = timeBlocksCollide(
            -(-start.format('x')), 
            -(-end.format('x')),
            -(-blockStart),
            -(-blockEnd),
            true
        );
        
        if (!collision.fitsInside) {
            return false;
        }
        
        return {
            price: -(-resource.newPrice)
        }
    }
        
    onDateChange(newDay, startTime, endTime) {
        const calendarType = this.settings.calendarType;
        
        if (calendarType === CALENDAR_TYPE.weekCalendar) {
            this.settings.baseDay = `${ newDay.format('YYYY-MM-DD') } ${ startTime }`;
        } else if ([CALENDAR_TYPE.resource, CALENDAR_TYPE.golf, CALENDAR_TYPE.grid].indexOf(calendarType) > -1) {
            // for resource type
            // get reservations and availabilities for the day
            // generate new resource table
            this.settings.startTime = `${ newDay.format('YYYY-MM-DD') } ${ startTime }`;
            this.settings.endTime = `${ newDay.format('YYYY-MM-DD') } ${ endTime }`;
        }
        
        //this.init();
    }

    // TODO: the mainBlock will have a reservations array when resource quantity allows > 1
    // use it to check available quantity for the new reservation
    generateReservation(index, table, config = {}) {
        const settings = this.settings;
        const {
            start: forceStart,
            end: forceEnd,
            soldReservationTimeId,
            label,
            shareId
        } = config;
        const allowedTypes = [// for extending
            TIMEBLOCK_TYPE.free,
            TIMEBLOCK_TYPE.startTooClose,
            TIMEBLOCK_TYPE.endTooClose,
            TIMEBLOCK_TYPE.reservationStillRoom,
            TIMEBLOCK_TYPE.startDisabled,
        ]; 
        const timeBlocks = [...table.timeBlocks]; // copy array to be sure that JS object references don't get mixed up
        const reservationId = settings.reservationId;
        //const lastBlock = timeBlocks[index - 1];
        //const lastBlockAvailable = typeof lastBlock !== 'undefined' && allowedTypes.indexOf(lastBlock.type) > -1;
        const mainBlock = timeBlocks[index];
        const mainResource = mainBlock.resource;
        const availableBlocks = [mainBlock];
        let nextBlocks = timeBlocks.slice(index + 1, timeBlocks.length);
        let selectedIndex = 0;
        let totalPrice = mainBlock.priceOverride !== false ? mainBlock.priceOverride : settings.price;
        let forceQuantity = null;
        let forceDuration = null;

        if (forceStart && forceEnd) {
            forceDuration = getDuration(forceStart, forceEnd, true)
            forceQuantity = durationToBlockCount(forceStart, forceEnd, settings.duration)
            mainBlock.selectDisabled = true;

            if (mainBlock.end.format('YYYY-MM-DD HH:mm:ss') === forceEnd) {
                nextBlocks = [];
            }
        }
        //console.log('mainBlock',mainBlock)
        mainBlock.duration = settings.duration;
        mainBlock.price = totalPrice;
        
        for(let i=0;i < nextBlocks.length;i++) {
            const block = nextBlocks[i];
            const legitRound = typeof block !== 'undefined' && 
            (
                allowedTypes.includes(block.type)
                || mainBlock.type == TIMEBLOCK_TYPE.resellableReservation
            )
            let selectDisabled = false;

            if (!legitRound) break;

            const duration = settings.duration * (i + 2);
            let available = true;
            
            totalPrice += block.priceOverride !== false ? block.priceOverride : settings.price;
            
            if (forceStart && forceEnd) {
                availableBlocks.push({
                    ...block,
                    duration,
                    price: totalPrice,
                    selectDisabled: true,
                })
                if (block.end.format('YYYY-MM-DD HH:mm:ss') === forceEnd) {
                    selectedIndex = i + 1;
                    break;
                }

                continue;
            }

            if (duration === settings.minDuration) {
                selectedIndex = i + 1;
            }
            
            if (!settings.admin) {
                if (!(duration <= settings.maxDuration)) {
                    break;
                }

                if (Array.isArray(settings.resourceRules) 
                    && settings.resourceRules.length > 0) {
                    const rules = settings.resourceRules
                        .filter(row => row.resourceId === mainResource.resourceId || row.resourceId === null)
                        .filter(row => validateResourceRule(row, block.start))
                        .filter(row => testRuleToBlock(row, block));
                    const userBlockOptionsRules = rules.filter(row => row.ruleName === 'lohkoAsetukset' && row.categoryId && row.isUserRule);
                    const commonBlockOptionsRules = rules.filter(row => row.ruleName === 'lohkoAsetukset' && !row.categoryId);
                    const blockOptionsRules = userBlockOptionsRules.length > 0 ? userBlockOptionsRules : commonBlockOptionsRules;

                    if (blockOptionsRules.length > 0) {
                        const nextBlock = nextBlocks[i + 1];
                        const nextBlockAvailable = typeof nextBlock !== 'undefined' && allowedTypes.indexOf(nextBlock.type) > -1;
                        const ruleValue = blockOptionsRules[0].ruleValue;

                        if (Array.isArray(ruleValue.disabledDurations)) {
                            const disabledBlock = ruleValue.disabledDurations.filter(row => row == duration)[0];

                            // disabledblock strict/loose rule as a setting??
                            // maxDuration has to be lower than nextDuration
                            // otherwise this could create 30 min gaps for example
                            if (disabledBlock && nextBlockAvailable) {
                                //console.log(disabledBlock, duration, block)
                                selectDisabled = true;
                            }
                        }
                    }
                }
            }

            if (available) {
                availableBlocks.push({
                    ...block,
                    duration,
                    price: totalPrice,
                    selectDisabled,
                })
            }
        }
        
        const getPriceClasses = (selectedIndex, orderQuantity = 1) => {
            const selectedBlocks = availableBlocks.slice(0, selectedIndex + 1);
            
            return selectedBlocks.reduce(
                (acc, curr) => {
                    let price = settings.price;

                    if (curr.priceOverride !== false) {
                        price = curr.priceOverride;
                    }

                    if (typeof acc[price] === 'undefined') {
                        acc[price] = {
                            quantity: 1 * orderQuantity,
                            blocks: [ curr ],
                            names: [ mainResource.resourceName ]
                        };
                    } else {
                        acc[price].quantity += 1 * orderQuantity;
                        acc[price].blocks.push(curr);
                        acc[price].names = Array.from(
                            new Set([
                                ...acc[price].names,
                                mainResource.resourceName
                            ])
                        )
                    }
                    
                    return acc;
                },
                {}
            );
        }
        const getPriceRows = (selectedIndex, orderQuantity = 1) => {
            const selectedBlocks = availableBlocks.slice(0, selectedIndex + 1);
            
            return selectedBlocks.map((row) => {
                let price = settings.price;

                if (row.priceOverride !== false) {
                    price = row.priceOverride;
                }
                
                return {
                    quantity: orderQuantity,
                    price
                }
            })
        };
        // zeroPrices used for example with membership stamps
        const getTotalPrice = (selectedIndex, zeroPrices = null) => {
            const selectedBlocks = availableBlocks.slice(0, selectedIndex + 1);
            
            return selectedBlocks
            .map((row, i) => {
                const tmp = JSON.parse(JSON.stringify(row));
                
                if (zeroPrices !== null && i < zeroPrices) {
                    tmp.priceOverride = 0;
                }

                return tmp;
            })
            .reduce(
                (acc, curr) => {
                    let price = settings.price;
                    
                    if (curr.priceOverride !== false) {
                        price = curr.priceOverride;
                    }

                    return acc + price;
                },
                0
            );
        }

        const disabledBlockDuration = (block) => {
            const { 
                admin:adminMode, 
                minDuration 
            } = settings;
            const { 
                duration, 
                selectDisabled 
            } = block;
            
            if (adminMode) {
                // soldReservationTimeId referred from outside scope (generateReservation function)
                return soldReservationTimeId && selectDisabled;
            }

            return minDuration > duration || selectDisabled;
        }
        
        let defaultOrderQuantity = 1;

        if (this.settings.calendarType == CALENDAR_TYPE.golf && this.settings.admin) {
            defaultOrderQuantity = 0;
        }

        const start = forceStart ? moment(forceStart) : mainBlock.start;
        const end = forceEnd ? moment(forceEnd) : availableBlocks[selectedIndex].end;
        const percentUnit = Math.round((1 / (availableBlocks.length + 1)) * 100);
        const reservationObj = {
            tableId: table.id,
            tableIndex: index,
            resourceId: mainResource.resourceId,
            resource: mainResource,
            title: table.resourceName,
            duration: settings.duration,
            selectedDuration: forceDuration || availableBlocks[selectedIndex].duration,
            selectedIndex,
            quantity: forceQuantity || selectedIndex + 1,
            orderQuantity: defaultOrderQuantity, // selected quantity per block
            availableQuantity: mainResource.quantity,  // available (reservations' quantities not counted into this!!)
            minQuantity: mainResource.minQuantity, // only affects orderQuantity detail
            maxQuantity: mainResource.maxQuantity, // only affects orderQuantity detail
            block: mainBlock,
            start,
            end,
            overNight: start.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD'),
            percent: 0,
            availableBlocks,
            priceClasses: getPriceClasses(selectedIndex),
            getPriceRows: getPriceRows(selectedIndex),
            price: getTotalPrice(selectedIndex),
            aPrice: settings.price,
            minDuration: settings.minDuration,
            maxDuration: settings.maxDuration,
            recurringReservation: false,
            recurringDates: [],
            reservations: mainBlock.reservations,
            soldReservationTimeId,
            label,
            shareId,
            getTotalPrice,
            adjustOrderQuantity(add, force = false) {
                let quantity = this.orderQuantity;

                if (add) {
                    if (quantity < mainResource.quantity) {
                        if (settings.admin && force) {
                            quantity += 1;
                        } else if (quantity < mainBlock.availableQuantity) {
                            quantity += 1;
                        }
                    }
                } else {
                    if (quantity > 0 && quantity > mainResource.minQuantity) {
                        quantity -= 1;
                    }
                }

                const i = this.selectedIndex;

                this.orderQuantity = quantity;
                this.priceClasses = getPriceClasses(i, this.orderQuantity);
                this.price = getTotalPrice(i);
            },
            setOrderQuantity(value, force = false) {
                const i = this.selectedIndex;
                
                if (settings.admin && force) {
                    this.orderQuantity = value;    
                } else {
                    if (!settings.admin && mainResource.maxQuantity < value) {
                        value = mainResource.maxQuantity;
                    }
                    // available quantity
                    if (mainBlock.availableQuantity < value) {
                        value = mainBlock.availableQuantity;
                    }

                    if (value >= mainResource.minQuantity) {
                        this.orderQuantity = value;
                    }
                }

                this.priceClasses = getPriceClasses(i, this.orderQuantity);
                this.price = getTotalPrice(i);
            },
            selectDuration(block, i = 0) {
                if (disabledBlockDuration(block)) {
                    return;
                }
                this.selectedDuration = block.duration;
                this.end = block.end;
                this.quantity = i + 1;
                this.percent = (i + 1) * percentUnit;
                this.selectedIndex = i;
                this.priceClasses = getPriceClasses(i, this.orderQuantity);
                this.price = getTotalPrice(i);

                if (this.start.format('YYYY-MM-DD') !== this.end.format('YYYY-MM-DD')) {
                    this.overNight = true;
                }
            },
            format() {
                const fullDuration = getDuration(this.start, this.end)
                const recurringReservation = this.recurringReservation;
                const priceClasses = getPriceClasses(this.selectedIndex);
                const rows = Object.keys(priceClasses)
                    .map((price) => {
                        const obj = priceClasses[price];
                        const blocks = obj.blocks;
                        const start = blocks[0].start.format('YYYY-MM-DD HH:mm:ss');
                        const end = blocks[blocks.length - 1].end.format('YYYY-MM-DD HH:mm:ss');
                        const { duration, resource } = blocks[0];
                        
                        return {
                            reservationId,
                            title: table.title,
                            duration,
                            fullDuration,
                            price,
                            start,
                            end,
                            quantity: obj.quantity,
                            resources: [{ ...resource, quantity: this.orderQuantity }],
                            recurringReservation,
                            soldReservationTimeId
                        }
                    });
                
                if (recurringReservation) {
                    // clone original rows for every recurring date
                    // these will become their own product row in an order
                    const recurringRows = [...rows];
                    
                    this.recurringDates.forEach((dates) => {
                        rows.forEach((row) => {
                            const clone = {...row}
                            const rowStart = moment(row.start)
                            const rowEnd = moment(row.end)
                            
                            clone.start = moment(dates.start).set({ 
                                hour: rowStart.format('H'), 
                                minute: rowStart.format('m') 
                            }).format('YYYY-MM-DD HH:mm:ss');
                            clone.end = moment(dates.end).set({ 
                                hour: rowEnd.format('H'), 
                                minute: rowEnd.format('m') 
                            }).format('YYYY-MM-DD HH:mm:ss');
                            
                            recurringRows.push(clone)
                        })
                    })
                    
                    return recurringRows;
                }
                
                // different prices make a new product row to the cart
                return rows;
            }
        }
        
        return reservationObj;
    }
}