<template>
<div class="survey-contactdetails">
    <div class="survey-contactdetails__firstName">
        <label for="firstName">{{ $_locale('first name') }}</label>
        <input type="text" 
            v-model="firstName" 
            id="firstName"
            name="firstName" 
            maxlength="85"
            :disabled="isFieldDisabled('firstName')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__familyName">
        <label for="lastName">{{ $_locale('last name') }}</label>
        <input type="text" 
            v-model="familyName" 
            for="lastName"
            name="lastName" 
            maxlength="85"
            :disabled="isFieldDisabled('familyName')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__email">
        <label for="email">{{ $_locale('email') }}</label>
        <input type="email" 
            v-model="email" 
            id="email"
            name="email" 
            maxlength="255"
            :disabled="isFieldDisabled('email')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__personFingerprint" v-if="optionEnabled('personFingerprint')">
        <label for="personFingerprint">{{ $_locale('Personal id number') }}</label>
        <input type="text" 
            v-model="personFingerprint" 
            id="personFingerprint"
            name="personFingerprint" 
            maxlength="20"
            :disabled="isFieldDisabled('personFingerprint')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__phone" v-if="optionEnabled('phone')">
        <label for="phone">{{ $_locale('phone') }}</label>
        <input type="text" 
            v-model="phone"
            id="phone" 
            name="phone" 
            maxlength="44"
            :disabled="isFieldDisabled('phone')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__title" v-if="optionEnabled('title')">
        <label for="title">{{ $_locale('Title') }}</label>
        <input type="text" 
            v-model="title" 
            id="title"
            name="title" 
            maxlength="44"
            :disabled="isFieldDisabled('title')"
            @input="onValueChange" />
    </div>
    <div class="survey-contactdetails__dateOfBirth" v-if="optionEnabled('dateOfBirth')">
        <label>{{ $_locale('date of birth') }}</label>
        <div>Birthday picker not yet implemented</div>
    </div>
    <div class="survey-contactdetails__gender" v-if="optionEnabled('gender')">
        <label for="gender">{{ $_locale('gender') }}</label>
        <select id="gender" 
            v-model="gender" 
            :disabled="isFieldDisabled('gender')"
            @change="onValueChange">
            <option value="male">{{ $_locale('male') }}</option>
            <option value="female">{{ $_locale('female') }}</option>
        </select>
    </div>
    <hr class="survey-contactdetails__separator" v-if="optionEnabled('address') || optionEnabled('country')" />
    <template v-if="optionEnabled('address')">
        <div class="survey-contactdetails__streetAddress">
            <label for="streetAddress">{{ $_locale('address') }}</label>
            <input type="text" 
                v-model="streetAddress" 
                id="streetAddress"
                name="streetAddress" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>
        <div class="survey-contactdetails__postalCode">
            <label for="postalCode">{{ $_locale('postal code') }}</label>
            <input type="text" 
                v-model="postalCode" 
                id="postalCode"
                name="postalCode" 
                maxlength="5"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>
        <div class="survey-contactdetails__city">
            <label for="city">{{ $_locale('postal city') }}</label>
            <input type="text" 
                v-model="city" 
                id="city"
                name="city" 
                maxlength="85"
                :disabled="isFieldDisabled('address')"
                @input="onValueChange" />
        </div>
    </template>
    <template v-if="optionEnabled('country')">
        <div class="survey-contactdetails__country">
            <label for="country">{{ $_locale('country') }}</label>
            <select id="country" 
                v-model="country" 
                :disabled="isFieldDisabled('country')"
                @change="onValueChange">
                <option value="">{{ $_locale('select country') }}</option>
                <option v-for="country in countries" 
                    :key="country.code" 
                    :value="country.code">
                    {{ country.name }}
                </option>
            </select>
        </div>
    </template>
    <template v-if="element.mailingList">
        <div class="survey-contactdetails__mailingList">
            <input id="mailinglist" 
                name="mailinglist" 
                type="checkbox" 
                v-model="allowMailingListAttachment"
                @change="onValueChange" />
            <label for="mailinglist">{{ $_locale('element.permissionQuestion') }}</label>
        </div>
    </template>
</div>
</template>

<script>
export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    data: () => ({
        countries: [],
        disabledFields: [],
        firstName: '',
        familyName: '',
        email: '',
        personFingerprint: '',
        phone: '',
        title: '',
        gender: 'male',
        streetAddress: '',
        postalCode: '',
        city: '',
        country: '',
        allowMailingListAttachment: false,
    }),
    computed: {
        isFieldDisabled() {
            const disabledFields = this.disabledFields

            return (name) => disabledFields.includes(name)
        },
        optionEnabled() {
            const { options } = this.element

            return (name) => options.includes(name)
        },
    },
    methods: {
        handleEmit() {
            const { options, type } = this.element
            const { firstName, familyName, email } = this
            const value = {
                type: 'contactDetails',
                firstName, familyName, email
            }

            options.forEach(option => {
                if (option === 'address') {
                    value['streetAddress'] = this.streetAddress
                    value['city'] = this.city
                    value['postalCode'] = this.postalCode
                } else if (option === 'gender') {
                    value['gender'] = this.gender
                } else {
                    value[option] = this[option]
                }
            })

            this.$emit('valueChange', { questionId: 'contactDetails', value, questionType: type })
        },
        // handle value and emit valueChange
        onValueChange() {
            this.$nextTick(this.handleEmit())
        },
    },
    mounted() {
        const settings = window.wnSurveySettings || {}

        if (settings.disabledFields && settings.disabledFields.contactDetails) {
            this.disabledFields = settings.disabledFields.contactDetails
        }
        
        if (this.initialValue) {
            Object.keys(this.initialValue).forEach(key => {
                const value = this.initialValue[key]
                
                this[key] = value
                console.log('contactDetails', this[key], value)
            })
        }

        if (window.countries) {
            this.countries = Object.keys(window.countries).map((key) => {
                return {
                    code: key, 
                    name: window.countries[key]
                }
            })
        }

        this.onValueChange()
    }
}
</script>