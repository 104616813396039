<template>
<div class="survey-question"
    :class="{ 'survey-linearscale__question-on-side': element.settings.showQuestionOnTheSide }">
    <label :for="`question-${ element.questionId }`" v-html="element.question"></label>
    <div class="survey-input-container">
        <div class="survey-linearscale__first-label" v-if="element.settings.labels[0]">
            {{ element.settings.labels[0] }}
        </div>
        <div class="survey-linearscale__scale">
            <label v-for="i in range" :key="`linearscale-${ element.questionId }-${ i }`">
                <span v-if="element.settings.showRange">{{ i }}</span>
                <input type="radio" 
                    v-model="value" 
                    :value="i" 
                    :name="'survey-linearscale-'+element.questionId" />
            </label>
        </div>
        <div class="survey-linearscale__second-label" v-if="element.settings.labels[1]">
            {{ element.settings.labels[1] }}
        </div>
    </div>
    <ExtraComments 
        v-if="element.settings.extra.comments && element.settings.extra.comments.show"
        :element="element"
        @valueChange="onCommentChange" />
</div>
</template>

<script>
import ExtraComments from './ExtraComments.vue'

export default {
    emits: ['valueChange'],
    props: {
        element: {
            type: Object,
            required: true,
        },
        initialValue: {
            required: false,
        },
    },
    components: {
        ExtraComments,
    },
    data: () => ({
        value: null,
        extra: {
            comments: '',
        },
    }),
    watch: {
        value(value) {
            const { questionId, questionType } = this.element

            this.$emit('valueChange', { questionId, value, extra: this.extra, questionType })
        },
    },
    computed: {
        range() {
            const { options, settings } = this.element
            const [ start, end ] = options
            const range = []

            for (let i = start; i <= end; i++) {
                if (!settings.showZero && i === 0) continue;

                range.push(i)
            }

            if (settings.showInverted) {
                return range.reverse()
            }

            return range;
        },
    },
    methods: {
        onCommentChange({ value }) {
            const { questionId, questionType } = this.element

            this.extra.comments = value
            this.$emit('valueChange', { 
                questionId, 
                questionType,
                value: this.value,
                extra: this.extra
            })
        },
    },
    mounted() {
        if (this.initialValue) {
            this.value = this.initialValue
        }
    }
}
</script>