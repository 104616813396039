
import { Preferences } from '@capacitor/preferences';
import { Browser } from '@capacitor/browser';
import { loadingController } from '@ionic/vue';
import { computed, watch } from 'vue';
import store from '@/store';
import settings from '@/WiseSettings.js'
import useGolfState from './GolfState';
import { _locale } from '../services/WiseLocale';

export default function useWiseGolf() {
    const GolfState = useGolfState()

    const loggedIn = computed(() => store.getters['user/loggedIn'])
    const selectedHost = computed(() => store.getters['common/selectedHost'])
    const notificationsSettings = computed(() => store.getters['common/notificationsSettings'])
    const restUrl = computed(() => store.getters['common/restUrl'])
    const baseUrl = computed(() => store.getters['common/baseUrl'])
    const ecomUrl = computed(() => store.getters['common/ecomUrl'])
    const appauth = computed(() => store.getters['common/appauth'])
    const ajaxUrl = computed(() => store.getters['common/ajaxUrl'])
    const adminUrl = computed(() => store.getters['common/adminUrl'])
    const adminMode = computed(() => store.getters['common/adminMode'])

    watch(loggedIn, (val) => {
        if (!val) {
            Array.from(document.body.classList)
            .forEach(row => {
                if (row.includes('golfclub-')) {
                    document.body.classList.remove(row)
                }
            })
        }
    })

    let consentLoading = null

    async function loadingOverlay() {
        consentLoading = await loadingController.create({
            cssClass: 'wise-login-overlay',
            message: _locale('login.checkConsent'),
        })
        await consentLoading.present()
    }

    return {
        ...GolfState,
        loggedIn,
        selectedHost,
        notificationsSettings,
        restUrl,
        baseUrl,
        ecomUrl,
        appauth,
        ajaxUrl,
        adminUrl,
        adminMode,
        async getClubMemory() {
            const { value } = await Preferences.get({ key: `selectedGolfClub-${ settings.appId }` });

            try {
                return JSON.parse(value);
            } catch(e) {
                // console.warn(e)
            }
            
            return null;
        },
        setClubMemory(club) {
            Preferences.set({ key: `selectedGolfClub-${ settings.appId }`, value: JSON.stringify(club) })
        },
        toggleAdminMode() {
            this.$store.commit('res_common/toggleAdminMode');
            this.$store.commit('res_reservations/setServiceAdminMode', this.adminMode);
        },
        async ecomCartAlert(data) {
            if (this.$route.path.includes('checkout')) {
                return false;
            }

            const { dispatch } = this.$store;
            this.$_wiseAlert({
                mode: 'ios',
                message: this.$t('ecom.cartHasReservation'),
                buttons: [
                    {
                        text: this.$t(data.orderId ? 'ecom.cartCancelReservation' : 'ecom.cartContinueShopping'),
                        handler: () => {
                            if (data.orderId) {
                                dispatch('res_ecom/deleteCartOrder')
                                .catch((error) => {
                                    this.$_wiseAlert({
                                        mode: 'ios',
                                        message: error,
                                        buttons: ['OK'],
                                    })
                                })
                                /*.finally(() => {
                                    location.reload();
                                })*/
                            }
                        },
                    },
                    {
                        text: this.$t('Proceed to checkout'),
                        handler: () => {
                            // redirect to checkout
                            this.$router.push('/golf/checkout')
                        },
                    },
                ],
            })
        },
        async onClubSelectionChange(evt) {
            const [ selectedClub ] = this.associatedClubs.filter(row => row.name === evt.target.value)
            const { appauth, urls, name, displayName } = selectedClub;
            const old = this.selectedClub;
            const approveHandler = () => {
                this.selectedClub = evt.target.value;
                // overlay animation 300ms
                setTimeout(() => this.onClubSelection(), 310)
            }
            
            if (typeof evt.target.blur === 'function') {
                evt.target.blur()
            }

            if (selectedClub.name === this.selectedHost) {
                this.$store.commit('user/mutateOverrideHostUser', null);
                approveHandler();
                return;
            } else {
                loadingOverlay()
                // check if selected club has already consented (ajax rest endpoint)
                // if yes -> approveHandler
                // if no ask permission
                const { success, personId, userId } = await this.$store.dispatch(
                    'user/consentUser', 
                    { checkConsent: true, name, appauth, ...urls }
                )
                consentLoading.dismiss()

                if (success) {
                    this.$store.commit('user/mutateOverrideHostUser', { personId, userId })
                    approveHandler();
                    return;
                }
            }
                  
            this.$_wiseAlert({
                mode: 'ios',
                message: this.$_locale(
                    'golf.consentPermissionQuestion',
                    true,
                    displayName
                ),
                backdropDismiss: false,
                buttons: [
                    {
                        text: this.$t('cancel'),
                        handler: () => {
                            evt.target.value = old;
                        }
                    },
                    {
                        text: this.$t('golf.readConsentManual'),
                        handler: () => {
                            Browser.open({ url: `${ urls.baseUrl }/tietosuojaseloste` });
                            return false;
                        }
                    },
                    {
                        text: this.$t('accept'),
                        handler: async () => {
                            const { success, personId, userId } = await this.$store.dispatch('user/consentUser', { name, appauth, ...urls });

                            if (success) {
                                this.$store.commit('user/mutateOverrideHostUser', { personId, userId })
                                approveHandler();
                            } else {
                                evt.target.value = old;
                            }
                        }
                    },
                ],
            })
        },
        async onClubSelection(save = true) {
            const { dispatch, commit } = this.$store;
            const [ selectedClub ] = this.associatedClubs.filter(row => row.name === this.selectedClub)

            if (!selectedClub) return;
            
            if (save) {
                this.setClubMemory(selectedClub.name);
            }
            
            if (selectedClub.name !== this.selectedHost) {
                const { appauth, urls, name, articleCategory } = selectedClub;

                commit('common/mutateOverrideHost', { name, appauth, articleCategory, ...urls })
                dispatch('user/updateUserSettings', this.notificationsSettings)
            } else {
                commit('common/mutateOverrideHost', null);
            }

            const [ bodyClass ] = selectedClub.name.split(' ');
            Array.from(document.body.classList)
            .forEach(row => {
                if (row.includes('golfclub-')) {
                    document.body.classList.remove(row)
                }
            })
            document.body.classList.add(`golfclub-${ bodyClass.toLowerCase() }`)
            
            this.$nextTick(async () => {
                const { golfClubId } = selectedClub;
                const serviceSettings = {
                    baseUrl: this.baseUrl,
                    restUrl: this.restUrl,
                    ecomUrl: this.ecomUrl,
                    ajaxUrl: this.ajaxUrl,
                    adminUrl: this.adminUrl,
                    appauth: this.appauth,
                }
                
                dispatch('res_ecom/setEcomServiceSettings', serviceSettings)
                dispatch('res_golf/setGolfServiceSettings', serviceSettings)
                await dispatch("common/getMobileAppSettings")
                // trigger to get user player information
                dispatch('res_golf/getPlayerInformation', { reset: true });
                dispatch('res_golf/getClubInformation', { reset: true });
                
                commit('res_golf/setDefaultClubId', golfClubId);

                const { data } = await dispatch('res_ecom/getMyCart')
                
                if (Array.isArray(data.rows)) {
                    const reservations = data.rows.filter((row) => row.type === 6);

                    if (reservations.length > 0) {
                        if (data.orderId) {
                            commit('res_reservations/setDisabled', true)
                        }

                        this.ecomCartAlert(data);
                    }
                }
            })
        },
        async checkConsent() {
            const [ selectedClub ] = this.associatedClubs.filter(row => row.name === this.selectedClub)

            if (!selectedClub) return;

            const { appauth, urls, name } = selectedClub;

            if (selectedClub.name === this.selectedHost) {
                return;
            }

            let checkConsent = true;
            // force consent for user :--D
            if (window.forceGolfConsent) {
                checkConsent = false;

                delete window.forceGolfConsent;
            }
            
            const { success, personId, userId } = await this.$store.dispatch(
                'user/consentUser', 
                { checkConsent, name, appauth, ...urls }
            )

            if (success) {
                this.$store.commit('user/mutateOverrideHostUser', { personId, userId })
            } else {
                const [ defaultOwner ] = this.associatedClubs.filter(row => row.name === this.selectedHost)

                this.selectedClub = defaultOwner?.name;
            }
        },
        async golfInit() { // maybe rename as reservation init and if-block all the golf stuff?
            const selectedClub = await this.getClubMemory();
            
            if (selectedClub) {
                this.setSelectedClub(selectedClub)
            } else {
                const [ defaultOwner ] = this.associatedClubs.filter(row => row.name === this.selectedHost);
                
                this.setSelectedClub(defaultOwner?.name)
            }
            
            await this.checkConsent();
            await this.onClubSelection(false);
        }
    }
}