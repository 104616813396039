import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
//import { Capacitor } from "@capacitor/core";
import { Preferences } from '@capacitor/preferences';
import { setupFilters } from './filters'
import settings from '@/WiseSettings.js';

// Mixins / helper functions
import commonFunctions from '@/helpers/commonFunctions'

// WiseStore
import WiseStore from './store';

// WiseApp modules
import WiseComponents from '@/components'
import WiseCore from '@/WiseCore';
import WiseGym from '@/WiseGym';
import WiseEcom from '@/WiseEcom'
import EcomReservation from '@/WiseEcom/EcomReservation'
import pushNotificationsHandler from '@/helpers/pushNotificationsHandler'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/main.css';
import moment from 'moment'
import { setupI18n, loadLocaleMessages, SUPPORT_LOCALES } from "@/i18n";

//const device = Capacitor.getPlatform()

console.log('App version', settings.appVersion)

export default (async () => {
	const mixins = [ pushNotificationsHandler ];

	if (settings.useGymFeatures) {
		const WiseGym = await import('@/WiseGym/mixins/WiseGym.js')
		/*
		const { IBeacon } = await import('@awesome-cordova-plugins/ibeacon')

		if (device === "android" || device === "ios") {
			IBeacon.requestAlwaysAuthorization().then(() => {
				// The delegate object holds the iBeacon callback functions (NEED TO BE CALLED IN 30 seconds from app start)
				WiseStore.commit("door/mutateIBeaconDelegate", IBeacon.Delegate())
			})
		}
		*/
		mixins.push(WiseGym.default)
	}
	/*
	if (settings.useReservationWebsocket) {
		const WiseWebSocket = await import('@/WiseEcom/mixins/WiseReservationsWebsocket.js')
		
		mixins.push(WiseWebSocket.default)
	}
	*/
	
	App.mixins = mixins;

	const { value: activeLang } = await Preferences.get({ key: `selectedLocale-${settings.appId}` });
	let userLocale = 'fi'	
	
	if (activeLang) {
		userLocale = activeLang
	}

	const i18n = setupI18n({
		legacy: false,
		locale: userLocale || 'fi',
		fallbackLocale: 'fi',
		globalInjection: true,
		silentFallbackWarn: true,
		silentTranslationWarn: true,
	});
	
	await loadLocaleMessages(i18n, userLocale || 'fi')

	const app = createApp(App)
		.use(i18n)
		.use(IonicVue, { innerHTMLTemplatesEnabled: true })
		.use(WiseStore)
		.use(WiseComponents, {})
		.use(WiseCore, {})
		.use(WiseGym, {})
		.use(WiseEcom, {})
		.use(EcomReservation, { i18n })
		.mixin(commonFunctions)
	// Global filters
	// maybe refactor this to the install format like wisecore, gym, etc?
	setupFilters(app, i18n)
	moment.locale(i18n.global.locale.value)
	WiseStore.commit('res_reservations/reset', { keepDate: false })

	// Add user full locale to vuex
	WiseStore.commit("common/mutateUserLocale", SUPPORT_LOCALES[userLocale].locale)

	const screenMode = await Preferences.get({ key: `screenMode-${settings.appId}` })

	if (screenMode.value) {
		console.log('dark mode preference found', screenMode.value === 'dark')
		WiseStore.commit('common/screenMode', screenMode.value)
	} else if (typeof settings.defaultScreenMode !== 'undefined') {
		console.log('dark mode default setting', settings.defaultScreenMode)
		WiseStore.commit('common/screenMode', settings.defaultScreenMode)
	}

	// Add host to vuex
	const selectedHost = await Preferences.get({ key: `selectedHost-${settings.appId}` })

	if (selectedHost.value) {
		WiseStore.commit('common/mutateSelectedHost', selectedHost.value)
	} else {
		const [ defaultHost ] = settings.hosts
		WiseStore.commit('common/mutateSelectedHost', defaultHost.name)
		await Preferences.set({ key: `selectedHost-${settings.appId}`, value: defaultHost.name })
	}	

	app.use(router)
	
	await router.isReady()

	// LOG USER IN IF HOST IS SELECTED AND VALID ACCESS TOKEN
	if (selectedHost.value) {
		// If selected host found, check if accessToken and validate token
		try {
			await WiseStore.dispatch("common/getMobileAppSettings")
			await WiseStore.dispatch("user/validateAccessTokenFromStorage")
		} catch(e) {
			console.log(e)
		}
	}

	app.mount('#app');

	return i18n
})();
